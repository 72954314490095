import React from 'react';
import { View, colors, Icon, ListItem, Text } from '@fairygodboss/ui';
import { Breifcase } from '../assets/svgs';

const iconMap = {
    shield: 'badge_20',
    wing: 'ratingWingOutline_20',
    diversity_performance: 'ribbon_20',
    thumbs_up: 'thumbsUp_20',
    trophy_icon: 'trophy_icon_20',
    megaphone: 'megaphone_20',
    scales: 'scales_20',
    giving_hand: 'giving_hand_20',
    diversity: 'diversity_20',
};

export const ListItemSVG = ({ icon, children }) => {
    const IconListItem = (
        <View
            bg={colors.info.light}
            width="34px"
            height="34px"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            display="flex"
            mr="2px"
        >
            {iconMap[icon] ? (
                <Icon
                    variant={iconMap[icon]}
                    outlineColor={colors.neutral[100]}
                />
            ) : (
                <View fontWeight="700" fontSize="14px" lineHeight="18px">
                    {icon}
                </View>
            )}
        </View>
    );

    return (
        <ListItem
            variant="icon"
            color={colors.neutral[100]}
            icon={IconListItem}
            alignItems="center"
            display="flex"
            css={{
                p: {
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: '400',
                    color: colors.neutral[100],
                    marginTop: '-6px',
                },
            }}
        >
            {children}
        </ListItem>
    );
};

export const JobPosting = () => (
    <View
        width="100%"
        maxWidth="466px"
        boxShadow="0px 12px 32px rgba(53, 53, 53, 0.24)"
        borderRadius="15px"
        height="fit-content"
        pb="16px"
    >
        <View
            bg={colors.info.light}
            borderTopRightRadius="15px"
            borderTopLeftRadius="15px"
            height="116px"
            py="32px"
            pl="16px"
            pr="24px"
        >
            <View display="flex">
                <Breifcase />
                <View pl="8px">
                    <View as="span" display="flex" alignItems="center">
                        <View
                            fontSize="20px"
                            color={colors.neutral[100]}
                            lineHeight="30px"
                            fontWeight={600}
                            minWidth="152px"
                            // overflow=""
                        >
                            Your Dream Job
                        </View>
                        <Icon
                            pl="16px"
                            variant="ratingWingFilled_20"
                            outlineColor={colors.primary[60]}
                        />
                        <View
                            as="p"
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="24px"
                            margin="0"
                            pl="5px"
                            color={colors.neutral[100]}
                        >
                            4.8
                        </View>
                    </View>
                    <Text
                        color={colors.neutral[70]}
                        fontSize="14px"
                        lineHeight="18px"
                        as="div"
                    >
                        Remote
                    </Text>
                </View>
            </View>
        </View>
        <View px="24px" pt="24px">
            <View
                fontSize="16px"
                color={colors.neutral[100]}
                lineHeight="24px"
                pb="24px"
                fontWeight={600}
            >
                <View
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight="600"
                    minWidth="290px"
                    color={colors.neutral[100]}
                >
                    Insights from Fairygodboss Members:
                </View>
            </View>
            <View as="ul" pl="0px" mb="0px">
                <ListItemSVG icon="shield">
                    On Fairygodboss’ Best Companies for Women of 2021
                </ListItemSVG>
                <ListItemSVG icon="wing">
                    <strong>4.8 / 5</strong> rating in overall job satisfaction
                </ListItemSVG>
                <ListItemSVG icon="thumbs_up">
                    <strong>4.4 / 5</strong> rating for supportive managers
                </ListItemSVG>
                <ListItemSVG icon="89%">
                    Reviewers say men and women are treated equally{' '}
                </ListItemSVG>
                <ListItemSVG icon="91%">
                    Reviewers say the CEO supports gender diversity{' '}
                </ListItemSVG>
            </View>
        </View>
    </View>
);
