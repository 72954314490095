import React from 'react';
import { View, Icon, colors } from '@fairygodboss/ui';
import { LinkedInIcon20 } from '../assets/svgs/LinkedInIcon20';
import { FgbFooterLogo } from '../assets/svgs/FgbFooterLogo';
import { TiktokIcon20 } from '../assets/svgs/TiktokIcon20';

export const FooterBrandAndSocialsSection = () => {
    return (
        <View
            display="flex"
            css={{
                flexDirection: 'column',
            }}
            mt="6px"
            mb="20px"
            ml={{
                _: '13px',
                xs: '16px',
                sm: '16px',
                md: '0px',
                lg: '0px',
                xl: '0px',
            }}
            justifyContent={{
                _: 'center',
                md: 'flex-start',
                lg: 'flex-start',
                xl: 'flex-start',
            }}
            width="240px"
        >
            <View display="flex" justifyContent="flex-start">
                <a
                    href="/"
                    css={{
                        WebkitTapHighlightColor: 'transparent',
                    }}
                    target="_self"
                    aria-label="FairyGodBoss"
                >
                    <FgbFooterLogo />
                </a>
            </View>
            <View display="flex" justifyContent="flex-start" mt="6px">
                <View
                    display="flex"
                    css={{
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                    }}
                    marginLeft="-10px"
                    width="240px"
                >
                    <a
                        href="https://www.facebook.com/fairygodboss/"
                        css={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                        rel="noreferrer"
                        target="_blank"
                        aria-label="Facebook"
                    >
                        <View
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                            height="40px"
                        >
                            <Icon
                                variant="facebook_20"
                                outlineColor={colors.white}
                            />
                        </View>
                    </a>
                    <a
                        href="https://www.instagram.com/fairygodboss/"
                        css={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                        rel="noreferrer"
                        target="_blank"
                        aria-label="Instagram"
                    >
                        <View
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                            height="40px"
                        >
                            <Icon
                                variant="instagram_20"
                                outlineColor={colors.white}
                            />
                        </View>
                    </a>

                    <a
                        href="https://twitter.com/fairygodboss/"
                        css={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                        rel="noreferrer"
                        target="_blank"
                        aria-label="Twitter"
                    >
                        <View
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                            height="40px"
                        >
                            <Icon
                                variant="twitter_20"
                                outlineColor={colors.white}
                            />
                        </View>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/fairygodboss/mycompany/"
                        css={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <View
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                            height="40px"
                        >
                            <LinkedInIcon20 />
                        </View>
                    </a>
                    <a
                        href="https://www.tiktok.com/@fairygodboss"
                        css={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <View
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="40px"
                            height="40px"
                        >
                            <TiktokIcon20 />
                        </View>
                    </a>
                </View>
            </View>
        </View>
    );
};
