import React, {
    useRef,
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react';
import NoSSR from 'react-no-ssr';
import * as Cookies from 'js-cookie';
import { ThemeProvider, View } from '@fairygodboss/ui';
import { PageContext } from '../../contexts/ContextCreator';
import GoogleOneTap from '../../components/Login/GoogleOneTap/GoogleOneTap';
import {
    MainValueProps,
    SupportingValueProps,
    AdviceBlock,
    JobsBlock,
    ArticlesAndResearchBlock,
    Events,
    RadioBlock,
    Testimonials,
    Footer,
    CookiesBanner,
} from './sections';
import { JoinCommunityButton } from './sections/MainValueProps';
import './HomePage.scss';
import { EmitPage } from '../../components/Analytics/VisibilityPixel/VisibilityPixel';
import {
    SnowplowPageViewTracker,
    PAGE_VIEW_SECTIONS,
} from '../../components/Analytics/Snowplow/SnowplowPageViewTracker';

const Homepage = ({ calendar, sponsoredCompanies }) => {
    const [hasCookie, setHasCookie] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const { pageProps, isLoggedIn } = useContext(PageContext);

    const setCookie = () => {
        const cookieName = 'FGBAllowCookies';
        const _hasCookie = Cookies.get(cookieName);
        setHasCookie(!!_hasCookie);
    };

    useEffect(() => {
        setCookie();
        EmitPage();
    }, []);

    const setCookieYes = useCallback(() => {
        Cookies.set('FGBAllowCookies', '1', { expires: 1095 });
        setCookie();
    }, []);

    const joinButtonRef = useRef();
    const [showStickyButton, setShowStickyButton] = useState(false);
    const windowScroll = useCallback(() => {
        if (joinButtonRef?.current) {
            const bottomOffset = joinButtonRef?.current?.getBoundingClientRect()
                ?.bottom;
            const widthIsMobile = window?.innerWidth < 600;
            if (widthIsMobile && bottomOffset < 0 && !showStickyButton) {
                setShowStickyButton(true);
                setInitialLoad(false);
                return;
            }

            if (!widthIsMobile || (bottomOffset > 0 && showStickyButton)) {
                setShowStickyButton(false);
            }
        }
    }, [showStickyButton]);

    useEffect(() => {
        windowScroll();
        window.addEventListener('scroll', windowScroll, false);
        window.addEventListener('resize', windowScroll, false);
        return () => {
            window.removeEventListener('scroll', windowScroll);
            window.removeEventListener('resize', windowScroll);
        };
    }, [windowScroll]);

    const BottomBanner = () => {
        return (
            <>
                <View
                    className={`bottom-banner-container ${
                        showStickyButton
                            ? 'bottom-banner-container-animated-in'
                            : !initialLoad &&
                              'bottom-banner-container-animated-out'
                    }`}
                    position="fixed"
                    display="flex"
                    width="100%"
                    css={{
                        justifyContent: 'center',
                        bottom: !hasCookie ? '144px' : '32px',
                        textDecoration: 'none !important',
                        WebkitUserSelect: 'none !important' /* Safari */,
                        MozUserSelect: 'none !important' /* Firefox */,
                        msUserSelect: 'none !important' /* IE10+/Edge */,
                        userSelect: 'none !important' /* Standard */,
                        WebkitTapHighlightColor: 'transparent',
                        'not(.touch):hover': {
                            color: '#FFF',
                        },
                    }}
                >
                    <JoinCommunityButton />
                </View>
                {!hasCookie ? (
                    <CookiesBanner
                        acceptCookies={setCookieYes}
                        closeBanner={setCookieYes}
                    />
                ) : null}
            </>
        );
    };

    const showGoogleoneTapLogin = () => {
        return (
            typeof window !== 'undefined' &&
            window?.document?.referrer.includes('google') &&
            !isLoggedIn()
        );
    };

    return (
        <ThemeProvider>
            <SnowplowPageViewTracker
                pageSection={PAGE_VIEW_SECTIONS.HOMEPAGE}
            />
            <View
                justifyContent="center"
                display="flex"
                overflowX="hidden"
                css={{
                    fontFamily: 'Open Sans',
                    'image-rendering':
                        '-webkit-optimize-contrast' /* Webkit (non-standard naming) */,
                }}
            >
                <View maxWidth="1240px" width="100%">
                    {showGoogleoneTapLogin() && (
                        <GoogleOneTap gclientId={pageProps.googleClientId} />
                    )}
                    <MainValueProps ref={joinButtonRef} />
                    <SupportingValueProps />
                    <AdviceBlock />
                    <JobsBlock sponsoredCompanies={sponsoredCompanies} />
                    <ArticlesAndResearchBlock />
                    <Events calendarEvents={calendar} />
                    <RadioBlock />
                    <Testimonials />
                    <Footer />
                    <BottomBanner />
                    <NoSSR>
                        <div
                            style={{ display: 'none' }}
                            data-qa-attr="jsIsReady"
                        >
                            JS Ready!
                        </div>
                    </NoSSR>
                </View>
            </View>
        </ThemeProvider>
    );
};

export default Homepage;
