import React from 'react';
import {
    View,
    Text,
    Button,
    Link,
    colors,
    Icon,
    Grid,
    GridColumn,
} from '@fairygodboss/ui';

export const CookiesBanner = ({
    acceptCookies = () => null,
    closeBanner = () => null,
}) => {
    return (
        <View
            css={{
                position: 'fixed',
                left: '0px',
                transform: 'translateX(-50%)',
                marginLeft: '50%',
                width: '100%',
                zIndex: '10',
                'a:link': {
                    'text-decoration': 'underline',
                    ':hover': {
                        color: '#611D6C !important',
                    },
                    ':visited': {
                        color: '#22385E !important',
                    },
                },
            }}
            bottom="16px"
            justifyContent="center"
        >
            <Grid
                css={{
                    position: 'relative',
                    margin: '0px',
                }}
                py="0px"
                px={{
                    _: '16px',
                    xs: '16px',
                    sm: '24px',
                    md: '24px',
                    lg: '24px',
                    xl: '24px',
                }}
            >
                <GridColumn
                    css={{
                        background: '#FFFFFF',
                        boxShadow: '0px 12px 32px rgba(53, 53, 53, 0.24)',
                        borderRadius: '10px',
                    }}
                    height={{
                        _: '112px',
                        xs: '112px',
                        sm: '56px',
                        md: '80px',
                        lg: '80px',
                        xl: '80px',
                    }}
                    position="relative"
                    size={{
                        _: 12,
                        xs: 12,
                        sm: 12,
                        md: 8,
                        lg: 8,
                        xl: 8,
                    }}
                    offset={{
                        _: 0,
                        xs: 0,
                        sm: 0,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                    display="flex"
                    justifyContent="center"
                    flexWrap="wrap"
                    alignItems="center"
                >
                    <View
                        maxWidth={{
                            _: '319px',
                            xs: '319px',
                            sm: 'none',
                            md: 'none',
                            lg: 'none',
                            xl: 'none',
                        }}
                    >
                        <Text
                            textAlign="center"
                            fontWeight="400"
                            fontSize="14px"
                            letterSpacing={{
                                _: 'normal',
                                sm: '-0.3px',
                                md: 'normal',
                            }}
                            lineHeight="24px"
                            color={colors.neutral[100]}
                            mx="0px"
                            mt={{
                                _: '9px',
                                xs: '9px',
                                sm: '0px',
                                md: '0px',
                                lg: '0px',
                                xl: '0px',
                            }}
                            mb="0px"
                        >
                            Our site uses cookies to improve your experience.{' '}
                        </Text>
                    </View>
                    <View
                        display="flex"
                        justifyContent="center"
                        flexWrap="nowrap"
                        alignItems="center"
                        ml="8px"
                    >
                        <View
                            display={{
                                _: 'none',
                                xs: 'none',
                                sm: 'inline-block',
                                md: 'inline-block',
                                lg: 'inline-block',
                                xl: 'inline-block',
                            }}
                        >
                            <Link
                                variant="standalone"
                                target="_self"
                                href="/privacy-policy"
                                css={{
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                }}
                            >
                                Read more
                            </Link>
                        </View>
                        <View
                            display={{
                                _: 'inline-block',
                                xs: 'inline-block',
                                sm: 'none',
                                md: 'none',
                                lg: 'none',
                                xl: 'none',
                            }}
                            my="8px"
                            mx={{
                                _: '16px',
                                xs: '16px',
                                sm: '16px',
                                md: '24px',
                                lg: '24px',
                                xl: '24px',
                            }}
                        >
                            <Button
                                width={{
                                    _: '106px',
                                    xs: '106px',
                                    sm: '71px',
                                    md: '71px',
                                    lg: '71px',
                                    xl: '71px',
                                }}
                                height="32px"
                                variant="primaryText"
                                css={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '18px',
                                    padding: '0px',
                                }}
                                onPress={() => {
                                    window.open('/privacy-policy', '_self');
                                }}
                            >
                                Read more
                            </Button>
                        </View>
                        <View
                            my="8px"
                            mx={{
                                _: '16px',
                                xs: '16px',
                                sm: '16px',
                                md: '24px',
                                lg: '24px',
                                xl: '24px',
                            }}
                        >
                            <Button
                                width={{
                                    _: '106px',
                                    xs: '106px',
                                    sm: '71px',
                                    md: '71px',
                                    lg: '71px',
                                    xl: '71px',
                                }}
                                onPress={() => {
                                    acceptCookies();
                                }}
                                height="32px"
                                variant="primary"
                                css={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '18px',
                                    padding: '0px',
                                }}
                            >
                                Got it!
                            </Button>
                        </View>
                    </View>
                    <View
                        position="absolute"
                        display="flex"
                        css={{
                            right: '0px',
                            top: '0px',
                            cursor: 'pointer',
                        }}
                        width="40px"
                        height={{
                            _: '40px',
                            xs: '40px',
                            sm: '100%',
                            md: '100%',
                            lg: '100%',
                            xl: '100%',
                        }}
                        onClick={() => {
                            closeBanner();
                        }}
                        onTap={() => {
                            closeBanner();
                        }}
                    >
                        <View paddingTop="18px" paddingLeft="8px">
                            <Icon
                                variant="close_16"
                                outlineColor={colors.neutral[60]}
                            />
                        </View>
                    </View>
                </GridColumn>
            </Grid>
        </View>
    );
};
