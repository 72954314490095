import React, { useRef, useCallback, useEffect, useState } from 'react';
import { View, Button, colors } from '@fairygodboss/ui';
import moment from 'moment-timezone';
import { CalendarEvent } from './components/CalendarEvent';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';
import { getMonthByNumber } from '../../../components/Utilities/TimeFunctions';

export const formatSponsorNames = (sponsors) => {
    const trueSponsers = sponsors.filter((sponsor) => sponsor.name);
    const trueSponserlength = trueSponsers.length;
    if (!sponsors || trueSponserlength === 0) return 'Fairygodboss';
    if (trueSponserlength === 1) return trueSponsers[0].name;
    if (trueSponserlength === 2)
        return `${trueSponsers[0].name} and ${trueSponsers[1].name}`;
    if (trueSponserlength === 3)
        return `${trueSponsers[0].name}, ${trueSponsers[1].name}, and ${trueSponsers[2].name}`;
    return 'Fairygodboss';
};

const BackgroundImage = ({ setImgHeight, imageHeight, width, setWidth }) => {
    const smallRef = useRef();
    const largeRef = useRef();
    const xsRef = useRef();

    const handleResize = useCallback(() => {
        window.requestAnimationFrame(() => {
            const _width = window?.innerWidth;
            if (_width) {
                setWidth(_width);
            }
            const height =
                xsRef?.current?.offsetHeight ||
                smallRef.current?.offsetHeight ||
                largeRef.current?.offsetHeight;
            if (height) {
                setImgHeight(height);
            }
        });
    }, [
        setImgHeight,
        setWidth,
        xsRef?.current,
        smallRef?.current,
        largeRef?.current,
    ]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 10);
    }, [handleResize]);

    useEffect(() => {
        window?.addEventListener('resize', handleResize);
        return () => window?.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const baseStyle = {
        zIndex: '-2',
        position: 'absolute',
        maxWidth: '100%',
        left: '0',
        margin: '0 auto',
        mt: '-3px',
        overflow: 'hidden',
        height: {
            _: '586px',
            sm: '776px',
            md: `${imageHeight + 20}px`,
        },
        maxHeight: {
            _: 'initial',
            md: `675px`,
        },
        minWidth: { md: '1300px', lg: '1229px' },
    };

    const ResponsiveImage = () => {
        return (
            <>
                <View
                    {...baseStyle}
                    display={{ _: 'none', md: 'inherit' }}
                    css={{
                        '@media screen and (min-width: 1600px)': {
                            img: {
                                height: 'max-content!important',
                            },
                        },
                        '@media not all and (min-resolution:.001dpcm)': {
                            '@supports (-webkit-appearance:none) and (stroke-color:transparent)': {
                                selector: {
                                    img: {
                                        height: 'auto!important',
                                    },
                                },
                            },
                        },
                    }}
                >
                    <img
                        src={buildImagePath(
                            '/v1/articles_upload/main/acqmdv6oeznxba4ixykt.webp',
                            'img',
                            {
                                img: 'c_scale,f_auto,q_auto',
                            }
                        )}
                        ref={largeRef}
                        alt="Logo"
                        width="100vw"
                        minWidth={{ md: '1100px', lg: '1440px' }}
                        style={{
                            width: '100vw',
                            overflow: 'hidden',
                            display: 'inline-block',
                            height: 'max-content',
                        }}
                    />
                </View>

                <View
                    {...baseStyle}
                    display={{ _: 'none', sm: 'inherit', md: 'none' }}
                >
                    <img
                        onLoad={handleResize}
                        ref={smallRef}
                        src={buildImagePath(
                            '/v1/articles_upload/main/acqmdv6oeznxba4ixykt.webp',
                            'img',
                            {
                                img: 'c_scale,f_auto,q_auto',
                            }
                        )}
                        alt="Logo"
                        width="100vw"
                        height="auto"
                        style={{
                            width: '100vw',
                            height: 'auto',
                            overflow: 'hidden',
                            minWidth: '787px',
                            marginLeft:
                                width < 787
                                    ? 'calc(calc(100vw - 787px) * 0.5)'
                                    : '0px',
                            alignSelf: 'flex-start',
                        }}
                    />
                </View>
                <View {...baseStyle} display={{ _: 'inherit', sm: 'none' }}>
                    <img
                        onLoad={handleResize}
                        ref={xsRef}
                        src={buildImagePath(
                            '/v1/articles_upload/main/acqmdv6oeznxba4ixykt.webp',
                            'img',
                            {
                                img: 'c_scale,f_auto,q_auto',
                            }
                        )}
                        alt="Logo"
                        width="110vw"
                        height="auto"
                        style={{
                            overflow: 'hidden',
                            alignSelf: 'flex-start',
                            minWidth: '448px',
                            width: '100%',
                            float: 'right',
                        }}
                    />
                </View>
            </>
        );
    };

    return <ResponsiveImage />;
};

const Convincer = () => {
    return (
        <View maxWidth={{ sm: '348px', md: '466px' }}>
            <View
                color={colors.neutral[0]}
                fontWeight={{ _: '700' }}
                fontSize={{ _: '32px', sm: '42px', md: '48px' }}
                lineHeight={{ _: '44px', sm: '56px', md: '70px' }}
                letterSpacing={{ _: '0px', sm: '0.25px', md: '-0.75px' }}
            >
                You’re invited!
            </View>
            <View
                fontSize={{ sm: '16px', md: '18px' }}
                lineHeight={{ sm: '26px', md: '28px' }}
                fontWeight="600"
                mt={{ _: '0px', sm: '24px', md: '24px' }}
                display={{ _: 'none', sm: 'flex' }}
                color={colors.neutral[0]}
            >
                Attend virtual career fairs, webinars, and more with industry
                experts. Expand your network and learn about companies who want
                to hire women like you.
            </View>
            <Button
                onPress={() => {
                    window.open('/events', '_self');
                }}
                css={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '-0.4px',
                }}
                display={{
                    _: 'none',
                    md: 'flex',
                }}
                mt={{ md: '32px', lg: '32px', xl: '28px' }}
                size="large"
            >
                View upcoming events
            </Button>
        </View>
    );
};

export const Events = ({ calendarEvents }) => {
    const [imgHeight, setImgHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setImgHeight(675);
    }, []);

    return (
        <View
            className="homepage-section"
            data-qa="events-block"
            key={imgHeight}
            height={{
                _: 'auto',
                sm: `${imgHeight + 394}px`,
                md: `675px`,
            }}
            maxHeight={{ _: 'auto', sm: 'auto', md: `${imgHeight + 20}px` }}
            position={{ _: 'relative', lg: 'inherit' }}
            overflow={{ _: 'hidden', sm: 'clip', xl: 'inherit' }}
            display={{ _: 'block', sm: 'flex' }}
            px={{ md: '24px', lg: '44px' }}
        >
            <View
                opacity="0.25"
                zIndex="-1"
                position="absolute"
                left="0"
                width="100vw"
                maxWidth="100%"
                height={{
                    _: '-webkit-fill-available',
                    sm: '776px',
                    md: `${imgHeight}px`,
                }}
                maxHeight={{
                    _: '586px',
                    sm: '776px',
                    md: '675px',
                }}
                background={{
                    _: 'transparent',
                    sm: '#140915',
                }}
            />
            <View
                opacity="0.7"
                zIndex="-1"
                position="absolute"
                left="0"
                width="100vw"
                maxWidth="100%"
                height={{
                    _: '-webkit-fill-available',
                    sm: '776px',
                    md: `${imgHeight}px`,
                }}
                maxHeight={{
                    _: '586px',
                    sm: '776px',
                    md: '675px',
                }}
                background={{
                    _:
                        'linear-gradient(359.81deg,#080409 50.46%,rgba(18,2,20,0) 102.31%)',
                    sm:
                        'linear-gradient(359.81deg,#080409 50.46%,rgba(18,2,20,0) 102.31%)',
                    md:
                        'linear-gradient(359.81deg, #080409 25.46%, rgba(18, 2, 20, 0) 73.31%)',
                }}
            />
            <BackgroundImage
                setImgHeight={setImgHeight}
                width={width}
                imageHeight={imgHeight}
                setWidth={setWidth}
            />
            <View
                justifyContent={{ sm: 'center', md: 'normal' }}
                display={{ sm: 'flex', md: 'block' }}
                width={{ sm: '100vw', md: 'inherit' }}
                flex={{ md: '5', lg: '7' }}
                pt={{
                    // _: '180px',
                    sm: '212px',
                    md: '310px',
                    lg: '352px',
                    xl: '344px',
                }}
                css={{
                    '@media screen and (min-width: 905px) and (max-width: 934px)': {
                        'padding-top': '220px',
                    },
                }}
                pr={{ _: '0px', md: '32px', lg: '0px' }}
            >
                <View
                    width={{ sm: '568px', md: 'inherit' }}
                    position={{ _: 'absolute', sm: 'inherit' }}
                    left={{ _: '16px', sm: null }}
                    top={{ _: `${imgHeight - 60}px`, sm: null }}
                >
                    <Convincer />
                </View>
            </View>
            <View
                bg={{ _: '#f5f7f9', md: 'inherit' }}
                width={{ _: '100vw', md: 'auto' }}
                height={{ sm: '394px', md: 'auto' }}
                position={{ sm: 'absolute', md: 'inherit' }}
                flex={{ md: '7', lg: '5' }}
                display={{ sm: 'grid', md: 'flex' }}
                justifyContent={{ sm: 'center', md: 'end' }}
                mt={{
                    _: `${imgHeight - 4}px`,
                    md: '201px',
                    lg: '292px',
                    xl: '296px',
                }}
            >
                <View
                    display="flex"
                    flexDirection={{ _: 'row', md: 'column' }}
                    overflow="inherit"
                    pt={{ _: '34px', sm: '32px', md: '0px' }}
                    pb={{ _: '56px', sm: '0px', md: '0px' }}
                    overflowX={{ _: 'scroll', sm: 'inherit' }}
                    px={{ _: '16px', sm: '0px' }}
                    css={{
                        '*': {
                            WebkitTapHighlightColor: 'transparent',
                        },
                    }}
                >
                    {calendarEvents.map((e, i) => {
                        const startDateUTC = new Date(
                            moment.tz(e?.startTime, 'UTC')
                        );
                        const momentStartTime = moment(startDateUTC).tz(
                            'America/New_York'
                        );
                        const momentEndTime = moment(
                            new Date(moment.tz(e?.endTime, 'UTC'))
                        ).tz('America/New_York');
                        const startTimeFormatted = momentStartTime.format(
                            'h:mm A'
                        );
                        const endTimeFormatted = momentEndTime.format('h:mm A');

                        const timeZone = 'ET';
                        const month = getMonthByNumber(
                            momentStartTime.format('M')
                        );
                        const day = momentStartTime.format('D');

                        const _sponsor = formatSponsorNames(e?.sponsors);

                        const secondEventStyle = {
                            pl: { _: '16px', md: '0px' },
                            pt: { _: '0px', md: '24px' },
                        };

                        const thirdEventStyle = {
                            display: { _: 'initial', sm: 'none' },
                            pl: { _: '16px', md: '0px' },
                            pt: { _: '0px', md: '24px' },
                        };

                        return (
                            <View
                                display={{ _: 'flex', sm: 'contents' }}
                                key={`calendar-event-${e.viewUrl}`}
                            >
                                <CalendarEvent
                                    month={month}
                                    day={day}
                                    time={`${startTimeFormatted}-${endTimeFormatted} ${timeZone}`}
                                    title={e.eventType}
                                    sponsor={`${e?.label || ''} ${
                                        _sponsor || ''
                                    }`}
                                    body={e.title}
                                    url={e.viewUrl}
                                    {...(i === 1 && secondEventStyle)}
                                    {...(i === 2 && thirdEventStyle)}
                                />
                            </View>
                        );
                    })}
                </View>
                <View
                    display={{ _: 'none', sm: 'flex', md: 'none' }}
                    justifyContent="center"
                    mt={{ sm: '40px' }}
                    pb={{ sm: '40px' }}
                >
                    <Button
                        data-qa="main-value-props-upcoming-events-button"
                        onPress={() => {
                            window.open('/events', '_self');
                        }}
                        css={{
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '20px',
                            letterSpacing: '-0.4px',
                            span: {
                                overflow: 'inherit',
                            },
                        }}
                        display={{
                            _: 'flex',
                            md: 'none',
                        }}
                        px="28px"
                        py="14px"
                        width="222px"
                        height="48px"
                        size="large"
                    >
                        View upcoming events
                    </Button>
                </View>
            </View>
        </View>
    );
};
