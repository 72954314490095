import React from 'react';

export const ConnectAndSupport = () => (
    <svg
        width="100%"
        height="172"
        viewBox="0 0 172 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>
            Two women exchanging messages on Fairygodboss about their career
            goals
        </title>
        <circle cx="86" cy="86" r="86" fill="#F2ECFE" />
        <g filter="url(#filter0_d_1357_6224)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M154.136 16H99.8635C94.9683 16 91 19.9365 91 24.7926V55.0357C91 59.8917 94.9658 64 99.8611 64C99.8611 64 103.519 64 106.249 64C108.262 66.1622 106.526 73.3388 105.791 75.9976C105.679 76.4026 106.069 76.7535 106.446 76.5675C108.818 75.396 115.176 71.7175 120 64C131.693 64 154.134 64 154.134 64C159.029 64 163 59.8917 163 55.0357V24.7926C163 19.9365 159.032 16 154.136 16Z"
                fill="white"
            />
        </g>
        <path
            d="M125.1 43.2013C124.547 43.2013 124.1 42.7536 124.1 42.2013V42.0742C124.1 41.3837 124.196 40.7744 124.388 40.2464C124.581 39.7082 124.889 39.2004 125.315 38.7232C125.74 38.2358 126.302 37.7382 127 37.2305C127.628 36.7837 128.129 36.3826 128.503 36.0272C128.888 35.6717 129.166 35.3214 129.338 34.9762C129.521 34.6208 129.612 34.2196 129.612 33.7728C129.612 33.1128 129.369 32.6102 128.883 32.2649C128.397 31.9196 127.719 31.747 126.848 31.747C125.978 31.747 125.112 31.8841 124.252 32.1583C123.696 32.3374 123.134 32.5533 122.566 32.806C122.058 33.0318 121.454 32.8338 121.207 32.3359L120.422 30.755C120.186 30.2781 120.363 29.6962 120.843 29.4659C121.614 29.0956 122.432 28.7847 123.295 28.5331C124.479 28.1777 125.775 28 127.182 28C129.349 28 131.024 28.523 132.208 29.5689C133.403 30.6046 134 31.9247 134 33.5291C134 34.3821 133.863 35.1234 133.59 35.753C133.327 36.3724 132.922 36.9512 132.375 37.4894C131.839 38.0174 131.166 38.5759 130.356 39.1649C129.748 39.6117 129.283 39.9874 128.959 40.2921C128.635 40.5967 128.412 40.9013 128.291 41.206C128.179 41.5004 128.124 41.8609 128.124 42.2874C128.124 42.7922 127.714 43.2013 127.21 43.2013H125.1ZM123.614 48.4106C123.614 47.4561 123.872 46.7859 124.388 46.4C124.915 46.0141 125.547 45.8212 126.286 45.8212C127.005 45.8212 127.623 46.0141 128.139 46.4C128.665 46.7859 128.928 47.4561 128.928 48.4106C128.928 49.3245 128.665 49.9845 128.139 50.3907C127.623 50.7969 127.005 51 126.286 51C125.547 51 124.915 50.7969 124.388 50.3907C123.872 49.9845 123.614 49.3245 123.614 48.4106Z"
            fill="#50668C"
        />
        <g filter="url(#filter1_d_1357_6224)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8635 95H72.1365C77.0317 95 81 98.9365 81 103.793V134.036C81 138.892 77.0342 143 72.1389 143C72.1389 143 68.4807 143 65.7511 143C63.7369 145.163 65.404 152.346 66.1116 155.001C66.2195 155.406 65.8285 155.753 65.4537 155.566C63.1069 154.393 56.8221 150.714 52 143C40.3068 143 17.866 143 17.866 143C12.9707 143 9 138.892 9 134.036V103.793C9 98.9365 12.9683 95 17.8635 95Z"
                fill="white"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.6806 109.184C49.6981 108.381 46.0379 110.547 45.1108 113.634C44.3261 110.402 40.3902 108.358 37.3194 109.184C34.2486 110.01 32.3953 113.299 33.18 116.531C33.9647 119.763 38.2782 123.189 44.6068 128.728C44.7566 128.86 44.8249 128.924 44.8872 128.962C44.9553 129.004 45.0386 129.012 45.1116 128.981C45.1799 128.953 45.2256 128.895 45.3932 128.728C50.8404 123.33 56.0352 119.763 56.82 116.531C57.6047 113.299 55.7514 110.01 52.6806 109.184Z"
            fill="#982EA9"
        />
        <circle cx="127" cy="127" r="36" fill="#E3C5E7" />
        <mask
            id="mask0_1357_6224"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="91"
            y="91"
            width="72"
            height="72"
        >
            <circle cx="127" cy="127" r="36" fill="#B394F2" />
        </mask>
        <g mask="url(#mask0_1357_6224)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.91 143.547C137.271 141.262 140.525 137.493 142.229 133.101C142.446 133.303 142.73 133.396 143.071 133.429C144.092 133.527 145.896 131.599 146.134 129.118C146.373 126.638 145.739 124.548 144.718 124.449C144.216 124.443 143.77 124.745 143.386 125.239C142.639 117.072 136.157 109.787 127.639 109.787C119.079 109.787 112.685 116.399 112.002 125.234C111.66 124.867 111.274 124.65 110.848 124.655C109.827 124.754 109.193 126.844 109.432 129.325C109.671 131.805 111.474 133.733 112.495 133.635C112.724 133.613 112.927 133.564 113.102 133.473C114.785 138.249 118.124 142.247 121.954 144.33C121.522 146.081 120.948 147.831 120.483 149.142C114.196 150.1 109.082 152.201 107.364 154.882C103.823 160.407 107.364 162.522 107.364 162.522H147.662C147.662 162.522 150.249 154.148 144.183 151.529C141.403 150.329 138.299 149.527 135.099 149.065C134.742 147.615 134.268 145.563 133.91 143.547Z"
                fill="#8F724F"
            />
            <path
                d="M106.317 154.882C107.557 152.946 113.152 150.262 120.478 148.966C122.935 153.88 127.849 158.079 127.849 158.079C127.849 158.079 132.54 154.595 135.265 148.966C140.045 148.966 144.163 151.287 146.031 152.093C152.096 154.712 147.662 162.522 147.662 162.522L127.849 164.37L107.364 162.522C107.364 162.522 102.776 160.407 106.317 154.882Z"
                fill="#2A4473"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M134.886 110.026C137.171 108.01 137.509 106.339 137.509 103.773C137.509 102.532 136.844 101.591 136.171 100.638C135.64 99.886 135.103 99.127 134.886 98.2081C134.373 96.0463 130.126 94.6626 127.133 94.6626C124.286 94.6626 121.35 95.5136 120.169 98.2081C119.912 98.7947 119.536 99.2512 119.158 99.7106C118.197 100.879 117.221 102.065 118.137 105.458C118.621 107.252 119.328 108.729 120.304 109.875C116.78 110.861 115.203 112.452 114.351 113.414C112.989 114.952 112.53 116.831 112.058 118.766C111.447 121.271 110.813 123.87 108.166 125.945C106.383 127.343 110.239 127.036 113.424 126.782C114.032 126.733 114.616 126.687 115.132 126.654C119.997 126.654 129.987 125.183 136.241 115.62C136.894 119.304 140.437 122.876 142.429 124.429C142.969 124.85 143.427 125.264 143.8 125.601C144.804 126.509 145.197 126.864 144.926 125.316C144.893 125.131 144.858 124.925 144.82 124.701C144.4 122.228 143.618 117.626 141.853 115.358C140.572 113.711 138.713 111.39 134.851 110.056C134.863 110.046 134.874 110.036 134.886 110.026Z"
                fill="#353535"
            />
        </g>
        <circle cx="45" cy="45" r="36" fill="#B394F2" />
        <mask
            id="mask1_1357_6224"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="9"
            y="9"
            width="72"
            height="72"
        >
            <circle cx="45" cy="45" r="36" fill="#D09FD8" />
        </mask>
        <g mask="url(#mask1_1357_6224)">
            <path
                d="M54.5993 50.8473C69.9976 57.0031 68.2973 60.0621 69.4514 69.5128C70.5093 78.176 46.8767 84.1933 24.2368 82.4213C14.7369 82.0066 19.9096 68.5535 22.8812 59.6448C24.6841 54.24 45.0995 50.4325 54.5993 50.8473Z"
                fill="#1B2B4A"
            />
            <path
                d="M54.598 50.847C57.6927 51.7646 58.2943 52.1056 59.9359 54.2126C61.4996 54.2808 59.0186 57.306 62.7902 60.604C63.4697 62.9836 62.8864 66.0917 57.6894 69.7816C50.4137 74.9474 41.4917 74.736 36.3401 74.3328C32.4372 74.0273 19.9083 68.5533 22.8799 59.6445C24.6828 54.2398 45.0981 50.4323 54.598 50.847Z"
                fill="#2A4473"
            />
            <path
                d="M54.6079 50.8475C60.8926 53.3599 64.645 54.0984 66.9048 56.0833C69.1139 59.3132 70.3751 63.2051 71.0582 68.7985C72.1716 75.8603 70.6472 75.2027 69.1176 71.0635C67.5719 66.8807 66.0029 59.1775 63.6492 59.0748C62.7649 61.386 60.9879 66.0088 55.4124 68.1155C49.0405 70.523 41.9444 71.5751 36.5112 70.4236C31.078 69.2721 27.7396 58.2597 26.6541 47.2454C26.0953 41.5753 45.1081 50.4327 54.6079 50.8475Z"
                fill="#50668C"
            />
            <path
                d="M62.0813 40.7395C62.0134 48.298 59.591 54.892 56.6041 58.7668C51.8466 64.9388 44.5827 65.202 39.9119 64.3049C35.1939 63.3987 29.4036 57.5177 30.9669 37.2804C31.3377 28.7891 39.0912 21.5307 47.2318 21.8861C56.7293 22.3008 62.452 32.2481 62.0813 40.7395Z"
                fill="#2A4473"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.2587 58.6647C57.6953 54.3337 62.2637 44.7564 62.3308 36.2998C62.7456 26.8 56.1357 15.6621 45.1896 15.1842C35.8072 14.7746 26.8822 22.8841 26.4675 32.3839C24.6955 55.0238 31.3798 62.3659 36.8184 63.3867C42.2027 64.3973 48.7849 65.563 54.2587 58.6647Z"
                fill="#50668C"
            />
            <path
                d="M57.5724 37.6586C56.3351 47.0049 50.1697 54.5692 43.4827 54.2773C37.1808 54.0021 30.4876 45.8762 30.1601 36.4618C29.8052 26.2627 36.4773 19.5038 44.2499 19.8432C52.0225 20.1825 58.9128 27.5335 57.5724 37.6586Z"
                fill="#E9CEAF"
            />
            <path
                d="M57.1824 28.7106C54.6615 21.9723 50.5482 19.6196 44.0512 19.6196C41.2362 19.6196 37.5939 20.1541 34.3299 23.145C31.3966 26.4011 29.5866 30.0045 30.1637 34.7298C30.6653 33.0393 31.0188 31.4927 33.2407 30.1476C35.6546 28.6863 34.5331 25.961 35.8253 24.777C40.7213 20.2906 41.0584 28.9222 46.9797 26.0474C57.8737 20.7582 49.9023 30.8751 54.6618 29.5162C57.7646 28.6303 56.7422 33.1363 57.7454 35.1268C58.0345 33.5266 57.6165 29.8709 57.1824 28.7106Z"
                fill="#353535"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1357_6224"
                x="90"
                y="13"
                width="78"
                height="66.6187"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="2" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1357_6224"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1357_6224"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d_1357_6224"
                x="4"
                y="93"
                width="78"
                height="66.6182"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-2" dy="1" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1357_6224"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1357_6224"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
