import React, { useEffect, useCallback } from 'react';
import axios from 'axios';

const GoogleOneTap = ({ gclientId }) => {
    const singInCallback = async (response) => {
        await axios.post('/auth-v2/google-onetap/callback', response);
        window.location.reload();
    };

    const initializeGoogleCredentials = useCallback(() => {
        window.google.accounts.id.initialize({
            client_id: gclientId,
            callback: singInCallback,
        });
        window.google.accounts.id.prompt(() => null);
    }, [gclientId]);

    const loadGoogleScript = useCallback(() => {
        const script = document.createElement('script');
        script.onload = () => {
            initializeGoogleCredentials();
        };
        script.src = `https://accounts.google.com/gsi/client`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }, [initializeGoogleCredentials]);

    useEffect(() => {
        if (!window.google) {
            loadGoogleScript();
        }
    }, [loadGoogleScript, initializeGoogleCredentials]);

    return <div style={{ display: 'none' }} />;
};

export default GoogleOneTap;
