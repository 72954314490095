import React from 'react';

export const LinkedInIcon20 = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <title>Check out Fairygodboss on Linkedin</title>
            <path
                d="M6.66732 15.8333H4.16732V6.66659H6.66732V15.8333ZM5.41732 5.60992C4.61232 5.60992 3.95898 4.95159 3.95898 4.13992C3.95898 3.32826 4.61232 2.66992 5.41732 2.66992C6.22232 2.66992 6.87565 3.32826 6.87565 4.13992C6.87565 4.95159 6.22315 5.60992 5.41732 5.60992ZM16.6673 15.8333H14.1673V11.1633C14.1673 8.35659 10.834 8.56909 10.834 11.1633V15.8333H8.33398V6.66659H10.834V8.13742C11.9973 5.98242 16.6673 5.82326 16.6673 10.2008V15.8333Z"
                fill="white"
            />
        </svg>
    );
};
