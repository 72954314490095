import React, { useRef } from 'react';
import { View, colors, Icon } from '@fairygodboss/ui';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';

const Section = ({ img, body, name, position, alt, ...others }) => {
    return (
        <View
            display={{ _: 'flex' }}
            flex={{ _: '4' }}
            px="24px"
            justifyContent={{ _: 'center' }}
            maxWidth="352px"
            {...others}
        >
            <View
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                textAlign="center"
            >
                <View>
                    <View
                        pb={{ _: '24px' }}
                        display="flex"
                        justifyContent="center"
                    >
                        <img
                            src={buildImagePath(img, 'img', {
                                img: 'c_scale,f_auto,q_auto,h_112',
                            })}
                            alt={alt}
                            style={{ width: '62px', height: '62px' }}
                        />
                    </View>
                    <View
                        fontSize={{ _: '16px' }}
                        lineHeight={{ _: '26px' }}
                        color={colors.neutral[100]}
                    >
                        {body}
                    </View>
                </View>
                <View
                    pt={{ _: '32px' }}
                    height={{ sm: '70px', md: '130px' }}
                    maxWidth={{ _: '422px', md: '195px' }}
                    alignSelf="center"
                >
                    <View
                        fontSize={{ _: '14px' }}
                        lineHeight={{ _: '24px' }}
                        fontWeight="600"
                        pb="4px"
                        color={colors.neutral[100]}
                    >
                        {name}
                    </View>
                    <View
                        fontSize={{ _: '14px' }}
                        lineHeight={{ _: '18px' }}
                        fontWeight="400"
                        color={colors.neutral[80]}
                        letterSpacing="0.4px"
                    >
                        {position}
                    </View>
                </View>
            </View>
        </View>
    );
};

const SimpleSlider = () => {
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <View
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="100%"
            pb="64px"
            css={{
                '.slick-slide': {
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: 'calc(100vw - 11px)',
                },
            }}
        >
            <View width="100%">
                <Slider ref={sliderRef} {...settings}>
                    <View>
                        <View justifyContent="center" display="flex">
                            <Section
                                img="./Homepage/images/Photo1.png"
                                alt="Headshot of Trinity Griffing, Business Relations Manager at Mission: St. Louis"
                                body="“I got a new job! I joined FGB after seeking advice for quitting my job and leaving a toxic workplace. I found incredible inspiration and community here and happily stayed and learned from so many women.”"
                                name="Trinity Griffin"
                                position="Business Relations Manager at Mission: St. Louis"
                            />
                        </View>
                    </View>
                    <View>
                        <View justifyContent="center" display="flex">
                            <Section
                                img="./Homepage/images/Photo4.png"
                                alt="Headshot of Anita Khandekar,Senior Technology Leader, Software Engineering at Wells Fargo"
                                body="“I got my job at Wells Fargo through Fairygodboss.”"
                                name="Anita Khandekar"
                                position="Senior Technology Leader, Software Engineering at Wells Fargo"
                            />
                        </View>
                    </View>
                    <View>
                        <View justifyContent="center" display="flex">
                            <Section
                                img="./Homepage/images/Photo2.png"
                                alt="Headshot of Robyn Wick, VIP, Director of Carlson MBA Program "
                                body="“I feel comfortable on Fairygodboss  because I know people are here for a purpose. It’s sometimes easier to ask for advice from people I may not know to gain a different perspective. I like encouraging people to bring their whole selves.”"
                                name="Robyn Wick, VIP"
                                position="Director of Carlson Executive MBA Program"
                            />
                        </View>
                    </View>
                </Slider>
            </View>
            <View>
                <View
                    display="flex"
                    width="120px"
                    justifyContent="space-between"
                >
                    <View
                        css={{
                            '*': {
                                WebkitTapHighlightColor: 'transparent',
                            },
                            button: {
                                background: 'none',
                                color: 'inherit',
                                border: 'none',
                                padding: 0,
                                font: 'inherit',
                                cursor: 'pointer',
                                outline: 'none',
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                    >
                        <Icon
                            aria-label="previous testimonial"
                            onClick={() => sliderRef?.current?.slickPrev()}
                            as="button"
                            pt="4px"
                            pl="6px"
                            variant="chevron_left_20"
                            outlineColor={colors.primary[60]}
                        />
                    </View>
                    <View
                        css={{
                            '*': {
                                WebkitTapHighlightColor: 'transparent',
                            },
                            button: {
                                background: 'none',
                                color: 'inherit',
                                border: 'none',
                                padding: 0,
                                font: 'inherit',
                                cursor: 'pointer',
                                outline: 'inherit',
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                    >
                        <Icon
                            aria-label="next testimonial"
                            onClick={() => sliderRef?.current?.slickNext()}
                            as="button"
                            pt="4px"
                            pl="6px"
                            variant="chevron_right_20"
                            outlineColor={colors.primary[60]}
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

export const Testimonials = () => {
    return (
        <View
            className="homepage-section"
            data-qa="testimonials-sections"
            pt={{ _: '0px', md: '80px' }}
            display={{ _: 'none', sm: 'flex' }}
            flexDirection="column"
            css={{
                '*': {
                    boxSizing: 'content-box !important',
                },
            }}
        >
            <View
                fontSize={{ sm: '42px', md: '52px' }}
                fontWeight="700"
                as="h3"
                lineHeight={{ sm: '56px', md: '70px' }}
                letterSpacing={{ sm: '-0.25px', md: '0.75' }}
                px={{ sm: null, md: '170px', lg: '240px' }}
                width={{ _: '534px', md: 'auto' }}
                alignSelf="center"
                display="flex"
                justifyContent="center"
                textAlign="center"
                pb="51px"
                color={colors.neutral[100]}
            >
                What members are saying about us
            </View>
            <View
                display={{ _: 'none', md: 'flex' }}
                flexDirection="row"
                justifyContent="center"
            >
                <Section
                    img="./Homepage/images/Photo1.png"
                    alt="Headshot of Trinity Griffing, Business Relations Manager at Mission: St. Louis"
                    body="“I got a new job! I joined FGB after seeking advice for quitting my job and leaving a toxic workplace. I found incredible inspiration and community here and happily stayed and learned from so many women.”"
                    name="Trinity Griffin"
                    position="Business Relations Manager at Mission: St. Louis"
                />
                <Section
                    img="./Homepage/images/Photo4.png"
                    alt="Headshot of Anita Khandekar,Senior Technology Leader, Software Engineering at Wells Fargo"
                    body="“I got my job at Wells Fargo through Fairygodboss.”"
                    name="Anita Khandekar"
                    position="Senior Technology Leader, Software Engineering at Wells Fargo"
                />
                <Section
                    img="./Homepage/images/Photo2.png"
                    alt="Headshot of Robyn Wick, VIP, Director of Carlson MBA Program "
                    body="“I feel comfortable on Fairygodboss  because I know people are here for a purpose. It’s sometimes easier to ask for advice from people I may not know to gain a different perspective. I like encouraging people to bring their whole selves.”"
                    name="Robyn Wick, VIP"
                    position="Director of Carlson Executive MBA Program"
                />
            </View>
            <View display={{ sm: 'flex', md: 'none' }}>
                <SimpleSlider />
            </View>
        </View>
    );
};
