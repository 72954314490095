import React from 'react';
import { View, Button } from '@fairygodboss/ui';
import Convincer from './components/Convincer';
import { RadioSpeakerImages } from './components/RadioSpeakerImages';
import { RadioSpeakerImagesSmall } from './components/RadioSpeakerImagesSmall';

export const RadioBlock = ({
    firstHeading = 'Hear from powerful women',
    firstSubheading = 'Listen to insightful conversations with prominent industry leaders on our podcast, Fairygodboss Radio.',
}) => {
    return (
        <View
            className="homepage-section"
            data-qa="radio-block"
            position="relative"
        >
            <View
                zIndex="-1"
                position="absolute"
                maxWidth="100%"
                overflowX="hidden"
                height="100%"
                css={{
                    transform: 'translateX(-50%)',
                    marginLeft: '50%',
                }}
            />
            <View
                height="100%"
                pl={{
                    _: '16px',
                    xs: '0px',
                    sm: '16px',
                    md: '24px',
                    lg: '44px',
                }}
                pr={{
                    _: '16px',
                    xs: '0px',
                    sm: '0px',
                    md: '24px',
                    lg: '44px',
                }}
            >
                <View
                    pt={{
                        _: '62px',
                        sm: '12px',
                        md: '64px',
                        lg: '64px',
                        xl: '64px',
                    }}
                    mt={{ _: '-12px', sm: '-30px', md: '-12px', lg: '0px' }}
                    mb={{
                        _: '48px',
                        sm: '86px',
                        md: '30px',
                        lg: '32px',
                        xl: '32px',
                    }}
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection={{ _: 'column', md: 'row' }}
                >
                    <Convincer
                        width={{
                            _: '100%',
                            xs: '100%',
                            sm: '100%',
                            md: '270px',
                            lg: '367px',
                            xl: '367px',
                        }}
                        mr={{ _: 24, xs: '0px', sm: '0px' }}
                        flex={{ _: 'inherit', md: 6, lg: 'inherit' }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                        pt={{
                            _: '112px',
                            xs: '0px',
                            sm: '62px',
                            md: '30px',
                            lg: '32px',
                            xl: '32px',
                        }}
                        internalPadding={{
                            _: '0px',
                            xs: '16px',
                            sm: '16px',
                            md: '0px',
                        }}
                        useFont18Body
                        heading={firstHeading}
                        subheading={firstSubheading}
                        maxWidth={{
                            _: '515px',
                            xs: '600px',
                            sm: '600px',
                            md: '270px',
                            lg: '367px',
                            xl: '367px',
                        }}
                        minWidth="270px"
                        buttonText="Browse episodes"
                        buttonWidth={181}
                        buttonDisplay={{
                            _: 'none',
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                            lg: 'flex',
                            xl: 'flex',
                        }}
                        onClickEvent={() => {
                            window.open('/community-podcasts', '_self');
                        }}
                    />
                    <View
                        display={{ _: 'inherit', xs: 'flex', sm: 'flex' }}
                        justifyContent={{
                            _: 'center',
                            md: 'inherit',
                            lg: 'inherit',
                            xl: 'inherit',
                        }}
                        flex={{ sm: 10, md: 6, lg: 6, xl: 5 }}
                    >
                        <RadioSpeakerImages
                            minWidth="563px"
                            display={{
                                _: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            }}
                        />
                        <RadioSpeakerImagesSmall
                            display={{
                                _: 'none',
                                xs: 'none',
                                sm: 'flex',
                                md: 'none',
                                lg: 'none',
                                xl: 'none',
                            }}
                        />
                        <RadioSpeakerImagesSmall
                            display={{
                                _: 'none',
                                xs: 'flex',
                                sm: 'none',
                                md: 'none',
                                lg: 'none',
                                xl: 'none',
                            }}
                            xsMode
                            mr={{ _: '16px', xs: '0px' }}
                        />
                    </View>
                    <View
                        display={{
                            _: 'none',
                            xs: 'none',
                            sm: 'flex',
                            md: 'none',
                        }}
                        css={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <View
                            css={{
                                width: '568px',
                            }}
                        >
                            <Button
                                data-qa="main-value-props-browser-episodes-button"
                                py="14px"
                                size="large"
                                mt="24px"
                                mb="24px"
                                margin="16px 0px"
                                letterSpacing="-0.4px"
                                alignItems="center"
                                height="48px"
                                width={181}
                                css={{
                                    span: {
                                        overflow: 'inherit',
                                    },
                                }}
                                onPress={() => {
                                    window.open('/community-podcasts', '_self');
                                }}
                            >
                                Browse episodes
                            </Button>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};
