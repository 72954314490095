import React from 'react';

export const NewJob = () => (
    <svg
        width="100%"
        height="172"
        viewBox="0 0 172 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>
            A hand holding a cell phone displaying a job search experience
        </title>
        <mask
            id="mask0_1357_6186"
            maskUnits="userSpaceOnUse"
            mask-type="alpha"
            x="0"
            y="0"
            width="172"
            height="172"
        >
            <circle cx="86" cy="86" r="86" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_1357_6186)">
            <circle cx="86" cy="86" r="86" fill="#F2ECFE" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.4911 103.594C53.0213 106.102 54.7912 112.399 52.7077 116.312C51.7622 118.088 52.8823 120.202 53.762 121.863C54.8207 123.862 55.5311 125.204 51.8721 124.504C45.1731 123.223 36.389 107.507 38.4725 103.594C41.1837 98.5022 42.9932 99.8048 45.7869 101.816C46.5916 102.395 47.478 103.033 48.4911 103.594ZM59.8781 129.139C52.2498 131.562 66.1986 137.988 74.2046 140.095C82.632 165.377 82.4256 175.45 82.4256 175.45L111.5 172.5L134.675 159.016C134.675 159.016 124.749 143.828 121.566 124.731C117.766 125.274 108.268 125.709 97.7787 126.188C82.1645 126.903 64.3548 127.717 59.8781 129.139Z"
                fill="#D7AE88"
            />
            <path
                d="M57.2002 25.947C57.7921 20.8471 62.1115 17 67.2456 17H108.031C114.049 17 118.736 22.2251 118.084 28.2086L107.417 126.067C106.951 130.342 103.341 133.581 99.0396 133.581H56.0633C50.0162 133.581 45.3208 128.309 46.0179 122.302L57.2002 25.947Z"
                fill="#22385E"
            />
            <path
                d="M58.454 22.3169C58.7496 19.7666 60.9095 17.8427 63.4768 17.8427H107.744C110.768 17.8427 113.115 20.4782 112.767 23.4814L101.116 123.99C100.82 126.54 98.6603 128.464 96.0929 128.464H51.8254C48.8021 128.464 46.4545 125.829 46.8026 122.826L58.454 22.3169Z"
                fill="white"
                stroke="#2A4473"
                strokeWidth="1.68547"
            />
            <rect
                x="75.27"
                y="20.6375"
                width="20.0061"
                height="1.81874"
                rx="0.909368"
                fill="#2A4473"
            />
            <path
                d="M53.537 107.179C53.6527 106.161 54.5136 105.393 55.5376 105.393H95.1296C96.3469 105.393 97.2858 106.465 97.1255 107.671L95.5338 119.656C95.4009 120.657 94.5474 121.405 93.5379 121.405H54.1756C52.9736 121.405 52.0393 120.358 52.1751 119.164L53.537 107.179Z"
                fill="#E3C5E7"
            />
            <path
                d="M55.5727 108.594C55.6597 107.734 56.3844 107.078 57.2496 107.078H93.3963C94.4156 107.078 95.2017 107.976 95.067 108.987L93.831 118.257C93.7193 119.094 93.005 119.719 92.1603 119.719H56.3131C55.3152 119.719 54.5358 118.857 54.6361 117.865L55.5727 108.594Z"
                fill="#982EA9"
            />
            <path
                d="M65.4611 116.692L65.2702 115.419H63.2962L62.7021 116.692H61.4651L64.2266 111.323H65.6302L66.6981 116.692H65.4611ZM65.1468 114.468L64.9512 113.225C64.9396 113.142 64.9231 113.036 64.9016 112.907C64.8829 112.775 64.8632 112.642 64.8425 112.508C64.8246 112.371 64.8113 112.253 64.8024 112.153C64.7619 112.253 64.7076 112.377 64.6396 112.526C64.5745 112.672 64.5117 112.811 64.4514 112.943C64.391 113.075 64.3477 113.169 64.3216 113.225L63.7357 114.468H65.1468ZM69.9597 111.345C70.6584 111.345 71.1447 111.493 71.4185 111.791C71.6928 112.086 71.7888 112.493 71.7065 113.013C71.6694 113.247 71.5981 113.471 71.4925 113.686C71.3873 113.898 71.2401 114.087 71.0509 114.253C70.8641 114.418 70.6285 114.55 70.3439 114.648C70.0597 114.743 69.7201 114.79 69.3251 114.79H68.8325L68.5313 116.692H67.3832L68.2302 111.345H69.9597ZM69.7533 112.274H69.2311L68.9797 113.861H69.3574C69.5722 113.861 69.7631 113.833 69.93 113.777C70.0969 113.721 70.233 113.633 70.3383 113.514C70.4437 113.394 70.5112 113.241 70.5409 113.053C70.5827 112.789 70.5395 112.594 70.4114 112.468C70.2838 112.338 70.0644 112.274 69.7533 112.274ZM74.7223 111.345C75.421 111.345 75.9073 111.493 76.1812 111.791C76.4554 112.086 76.5514 112.493 76.4691 113.013C76.432 113.247 76.3607 113.471 76.2551 113.686C76.1499 113.898 76.0027 114.087 75.8135 114.253C75.6267 114.418 75.3911 114.55 75.1065 114.648C74.8223 114.743 74.4827 114.79 74.0877 114.79H73.5951L73.2939 116.692H72.1458L72.9928 111.345H74.7223ZM74.5159 112.274H73.9937L73.7423 113.861H74.12C74.3348 113.861 74.5257 113.833 74.6926 113.777C74.8595 113.721 74.9956 113.633 75.1009 113.514C75.2063 113.394 75.2738 113.241 75.3036 113.053C75.3453 112.789 75.3021 112.594 75.174 112.468C75.0464 112.338 74.827 112.274 74.5159 112.274ZM76.9084 116.692L77.7554 111.345H78.9035L78.2048 115.756H80.4009L80.2526 116.692H76.9084ZM83.3803 113.547L84.8586 111.345H86.0955L83.782 114.611L83.4524 116.692H82.3117L82.6356 114.648L81.3626 111.345H82.6069L83.3803 113.547Z"
                fill="white"
            />
            <ellipse
                rx="15.4593"
                ry="15.4593"
                transform="matrix(1 0 -0.0908066 0.995869 82.2674 44.942)"
                fill="#E9ECF1"
            />
            <rect
                width="18.9732"
                height="13.865"
                rx="1.69067"
                transform="matrix(1 0 -0.140037 0.990146 74.0879 38.697)"
                fill="#2A4473"
            />
            <g filter="url(#filter0_d_1357_6186)">
                <rect
                    width="18.9732"
                    height="6.20277"
                    rx="1.69067"
                    transform="matrix(1 0 -0.140037 0.990146 74.0879 38.697)"
                    fill="#50668C"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.4143 35.4458C81.2938 35.4458 80.2583 36.3452 80.1014 37.4546L79.9256 38.6973H81.0202L81.1374 37.8686C81.242 37.129 81.9324 36.5294 82.6794 36.5294H85.0825C85.8294 36.5294 86.3502 37.129 86.2456 37.8686L86.1284 38.6973H87.223L87.3988 37.4546C87.5557 36.3452 86.7745 35.4458 85.6541 35.4458H82.4143Z"
                fill="#9DA9BF"
            />
            <rect
                width="1.45948"
                height="2.24013"
                rx="0.338134"
                transform="matrix(1 0 -0.140039 0.990146 82.1021 43.9487)"
                fill="#C4CBD8"
            />
            <rect
                x="60.7209"
                y="67.5642"
                width="40.4513"
                height="3.37094"
                rx="1.68547"
                fill="#C4CBD8"
            />
            <rect
                x="57.3501"
                y="80.2051"
                width="42.1368"
                height="3.37094"
                rx="1.68547"
                fill="#C4CBD8"
            />
            <rect
                x="55.6646"
                y="92.8462"
                width="42.9795"
                height="3.37094"
                rx="1.68547"
                fill="#C4CBD8"
            />
            <path
                d="M102.803 95.5379C83.667 105.736 76.52 100.193 75.7121 96.0727C78.1788 94.4089 85.0801 89.3026 92.9517 82.1875C102.791 73.2936 101.365 73.2604 116.682 83.5615C134.631 95.6328 119.41 130.549 116.682 138.278C113.018 148.659 113.065 141.461 102.803 141.461C102.803 141.461 104.659 141.072 106.262 130.714C107.09 125.363 108.689 109.411 109.168 104.177C104.167 102.358 102.803 95.5379 102.803 95.5379Z"
                fill="#D7AE88"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.1159 70.6351C67.5852 67.0424 65.5015 65.0303 61.5943 61.2575L61.5193 61.185C61.1784 60.8559 60.8499 60.5298 60.5303 60.2127L60.5302 60.2126C57.141 56.8492 54.759 54.4854 49.4112 60.0231C45.9197 63.6387 45.9855 66.1253 49.9409 69.9449C50.1479 70.1448 50.3624 70.3355 50.5837 70.517C48.4086 70.4395 46.4203 71.699 44.3621 75.4898C42.1619 79.542 42.6145 82.1145 46.5669 84.8345C44.9595 85.092 43.4483 86.3253 41.892 89.1917C40.0154 92.6479 40.3165 94.8895 43.4645 97.2019C42.0683 97.6927 40.7063 99.0475 39.2645 101.755C36.8883 106.218 37.7318 108.689 42.8985 111.549C48.0652 114.41 54.9742 113.264 57.3504 108.801C59.586 104.603 57.265 103.029 52.6801 100.475C56.1557 100.492 59.3123 99.1677 60.7046 96.6035C62.9314 92.5022 60.2484 90.9352 55.1246 88.1512C59.5826 88.5765 63.833 87.091 65.5909 83.8535C68.157 79.1273 64.9451 77.3835 58.9225 74.1137L58.8068 74.0509C58.2814 73.7656 57.7714 73.481 57.2753 73.2041L57.0298 73.0672C59.7024 73.2592 62.3317 72.4826 64.1159 70.6351Z"
                fill="#D7AE88"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_1357_6186"
                x="70.7341"
                y="37.3445"
                width="24.812"
                height="11.5517"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1.35254" />
                <feGaussianBlur stdDeviation="1.35254" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0.2 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1357_6186"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1357_6186"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
