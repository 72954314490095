import React from 'react';
import { LOGIN_LAYOUT } from '../../../../components/Login/LoginLayoutComponent/Layouts/LogInLayout/constants';
import { Consumer as PageConsumer } from '../../../../contexts/PageContext';

export const AuthWrapper = ({
    component,
    redirectUrl,
    loginTrigger,
    customMetric,
    hash = 'Homepage',
    cookie,
}) => {
    return (
        <PageConsumer>
            {(context) =>
                context.wrapAuth(
                    hash,
                    component,
                    { login_trigger: loginTrigger, ...customMetric },
                    null,
                    LOGIN_LAYOUT,
                    cookie,
                    redirectUrl
                )
            }
        </PageConsumer>
    );
};
