import React from 'react';

export const StayInformed = () => (
    <svg
        width="100%"
        height="184"
        viewBox="0 0 172 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>An open envelope with a Fairygodboss newsletter inside</title>
        <circle cx="86" cy="98" r="86" fill="#F2ECFE" />
        <path
            d="M81.2587 24.5239C83.3817 22.6367 86.581 22.6367 88.704 24.5239L146.247 75.6731L87.0379 114.721C85.7903 115.544 84.1724 115.544 82.9247 114.721L23.7158 75.6731L81.2587 24.5239Z"
            fill="#2A4473"
        />
        <g filter="url(#filter0_d_1357_6149)">
            <rect
                x="46.4434"
                y="30.1084"
                width="77.076"
                height="87.9457"
                rx="3.54286"
                fill="white"
            />
            <path
                d="M52.3723 45.7138C52.3723 44.7355 53.1654 43.9424 54.1437 43.9424H115.819C116.797 43.9424 117.59 44.7355 117.59 45.7138V49.088C117.59 50.0664 116.797 50.8595 115.819 50.8595H54.1437C53.1654 50.8595 52.3723 50.0664 52.3723 49.088V45.7138Z"
                fill="#9DA9BF"
            />
            <circle cx="54.3486" cy="38.0137" r="1.97631" fill="#1FA5A4" />
            <circle cx="62.2539" cy="38.0137" r="1.97631" fill="#FDBE61" />
            <circle cx="70.1592" cy="38.0137" r="1.97631" fill="#FB6566" />
            <rect
                x="82.0171"
                y="58.7646"
                width="35.5735"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <rect
                x="82.0171"
                y="68.6462"
                width="35.5735"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <rect
                x="82.0171"
                y="78.5278"
                width="35.5735"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <rect
                x="52.3723"
                y="88.4094"
                width="65.2181"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <rect
                x="52.3723"
                y="98.2908"
                width="65.2181"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <rect
                x="52.3723"
                y="108.172"
                width="65.2181"
                height="1.97631"
                rx="0.988154"
                fill="#C4CBD8"
            />
            <circle cx="64.3723" cy="68.7883" r="12" fill="#982EA9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.9218 64.3376C68.4271 64.0277 69.26 63.5179 70.1645 62.3135C70.3481 62.3135 70.7656 63.0886 70.7258 63.8692L70.7251 63.8825C70.6794 64.7881 70.5933 66.4905 67.9631 67.7351C68.5063 67.811 69.2588 67.7723 69.9727 67.4599C69.9727 68.7088 68.9025 71.0504 65.3764 71.2846C65.574 71.753 66.4857 71.8312 67.0403 71.8703C66.4849 73.0414 64.9797 73.7828 63.236 73.8219C63.0562 73.8259 62.8125 73.8068 62.5336 73.785C61.9127 73.7364 61.117 73.6742 60.4627 73.8219C59.3898 74.0641 58.9724 74.241 58.2389 75.1445C58.2389 75.0676 58.2095 74.9069 58.168 74.6796C57.9165 73.3024 57.2192 69.4833 59.9081 67.1088C59.959 67.0638 60.0088 67.0195 60.0578 66.9759C61.1154 66.0342 61.8475 65.3823 66.9218 64.3376ZM66.1114 65.6095C67.1904 65.3874 68.7088 65.0689 69.6221 64.3723C69.4208 65.1942 69.2296 65.4777 68.7813 65.926C68.2027 66.5103 66.0398 67.4857 64.7312 68.0758C64.5488 68.1581 64.383 68.2328 64.2404 68.298L64.2662 68.3016C64.7498 68.3693 67.7455 68.7883 68.366 68.853C67.72 69.71 66.5201 70.2637 63.8239 70.3165C63.8606 70.3957 63.9051 70.5226 63.959 70.6764C64.1475 71.2138 64.4513 72.0797 64.939 72.39C64.8721 72.4392 64.4765 72.6567 63.3097 72.7425C63.1162 72.7467 62.8786 72.7285 62.6086 72.7077C62.2987 72.6839 61.9463 72.6567 61.5693 72.6567C60.8639 72.6567 59.9014 72.7227 59.0246 73.138C59.0242 73.1338 59.0237 73.1291 59.0231 73.124C58.9789 72.7178 58.6352 69.5585 60.7123 67.8114C60.7657 67.7664 60.8173 67.7228 60.8674 67.6803C61.8696 66.8317 62.3195 66.4508 66.1114 65.6095Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.6222 64.3723C68.7089 65.0689 67.1905 65.3874 66.1115 65.6095C62.1297 66.4929 61.8331 66.8687 60.7124 67.8114C58.6093 69.5804 58.988 72.7973 59.0247 73.138C59.9015 72.7227 60.864 72.6567 61.5694 72.6567C62.2748 72.6567 62.8942 72.7516 63.3098 72.7424C64.4766 72.6567 64.8721 72.4392 64.9391 72.39C64.3118 71.9909 63.9888 70.6724 63.824 70.3165C66.5202 70.2637 67.72 69.71 68.3661 68.853C67.7332 68.787 64.6299 68.3525 64.2405 68.298C65.4061 67.7652 68.1222 66.5918 68.7814 65.926C69.2297 65.4777 69.4209 65.1942 69.6222 64.3723Z"
                fill="white"
            />
        </g>
        <path
            d="M23.7158 75.5632L84.9813 115.733L146.247 75.5633V141.806C146.247 146.697 142.281 150.663 137.39 150.663H32.573C27.6813 150.663 23.7158 146.697 23.7158 141.806L23.7158 75.5632Z"
            fill="#7787A6"
        />
        <path
            d="M23.7158 75.5632L84.7956 115.716C84.8095 115.725 84.8212 115.733 84.8352 115.742C85.0818 115.903 88.5845 118.183 138.342 150.576C137.626 150.694 136.695 150.658 135.178 150.658C133.618 150.654 130.622 150.658 128.896 150.658C124.908 150.658 57.1456 150.658 32.5469 150.658C27.6553 150.658 23.7158 146.692 23.7158 141.801L23.7158 75.5632Z"
            fill="#9DA9BF"
        />
        <rect
            x="15.8105"
            y="43.5408"
            width="7.49765"
            height="7.49765"
            transform="rotate(-10.5 15.8105 43.5408)"
            fill="#982EA9"
        />
        <rect
            width="5.62324"
            height="5.62324"
            transform="matrix(0.942641 0.333807 -0.333806 0.942642 97.877 0)"
            fill="#E3C5E7"
        />
        <rect
            width="6.56044"
            height="6.56044"
            transform="matrix(0.948324 -0.317303 0.317307 0.948323 143.271 51.7534)"
            fill="#BD79C8"
        />
        <rect
            x="33.6174"
            y="19.6814"
            width="5.62323"
            height="5.62324"
            transform="rotate(-45 33.6174 19.6814)"
            fill="#B394F2"
        />
        <rect
            width="4.68603"
            height="4.68603"
            transform="matrix(0.920504 -0.390734 0.390728 0.920506 147.02 27.1355)"
            fill="#B394F2"
        />
        <path
            d="M56.5789 14.5264C57.6723 10.7776 62.0147 3.84228 70.6369 6.09158"
            stroke="#982EA9"
            strokeWidth="1.89688"
            strokeLinecap="round"
        />
        <path
            d="M131.957 10.8279C133.212 14.5259 133.633 22.6977 125.28 25.8008"
            stroke="#B394F2"
            strokeWidth="1.89688"
            strokeLinecap="round"
        />
        <defs>
            <filter
                id="filter0_d_1357_6149"
                x="39.3576"
                y="26.5655"
                width="91.2474"
                height="102.117"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="3.54286" />
                <feGaussianBlur stdDeviation="3.54286" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0.2 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1357_6149"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1357_6149"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
