import React from 'react';
import { View, colors, Icon, useViewport } from '@fairygodboss/ui';

const Date = ({ month, date, time }) => {
    const { width } = useViewport();
    const isSm = width <= 904;

    return (
        <View
            width={{ _: '100%', md: 'inherit' }}
            display="flex"
            flexDirection={{ _: 'row', md: 'column' }}
            ml={{ _: '16px', md: '0px' }}
            height={{ _: 'initial', sm: 'inherit' }}
        >
            <View
                fontWeight="700"
                fontSize="16px"
                lineHeight="24px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={colors.neutral[100]}
            >
                {isSm ? month : month.slice(0, 3)}
            </View>
            <View
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={{ _: '', md: '44px' }}
                fontWeight="700"
                ml={{ _: '4px', md: '0px' }}
                fontSize={{ _: '16px', md: '32px' }}
                lineHeight="44px"
                color={colors.neutral[100]}
            >
                {date}
            </View>
            <View
                ml={{ _: '12px', sm: '16px', md: '0px' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize={{ _: '14px', md: '12px' }}
                lineHeight={{ _: '18px', md: '14px' }}
                fontWeight="400"
                color={colors.neutral[80]}
                textAlign={{ _: 'inherit', md: 'center' }}
            >
                {time}
            </View>
        </View>
    );
};

const Body = ({ title, sponsor, body }) => {
    const { width } = useViewport();
    const isSm = width <= 904;

    const sponsorCss = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1' /* number of lines to show */,
        lineClamp: '1',
        '-webkit-box-orient': 'vertical',
    };
    if (isSm) {
        sponsorCss['-webkit-line-clamp'] = '2';
        sponsorCss.lineClamp = '2';
    }

    return (
        <View
            px="16px"
            py={{ _: '12px', md: '16px' }}
            height={{
                _: '100%',
                sm: '100%',
                // sm: 'calc(100% - 8px)',
                md: '-webkit-fill-available',
            }}
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
        >
            <View>
                <View
                    display="flex"
                    flexDirection={{ _: 'column', md: 'row' }}
                    flexWrap="wrap"
                    pb={{ _: '16px', sm: '12px', md: '12px' }}
                >
                    <View
                        fontSize={{ _: '16px' }}
                        lineHeight={{ _: '24px' }}
                        fontWeight={{ _: '600' }}
                        maxWidth={{ _: '100%', sm: '210px', md: '100%' }}
                        color={colors.primary[60]}
                        width={{ md: 'auto' }}
                    >
                        {`${title.toUpperCase()}`}&nbsp;&nbsp;
                    </View>
                    <View
                        color={colors.neutral[70]}
                        fontSize="16px"
                        lineHeight="26px"
                        width={{ md: 'auto' }}
                        display={{ _: 'none', md: '-webkit-box' }}
                        css={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '-webkit-line-clamp':
                                '1' /* number of lines to show */,
                            lineClamp: '1',
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {sponsor}
                    </View>
                    <View
                        color={colors.neutral[70]}
                        fontSize="16px"
                        lineHeight="26px"
                        width={{ md: 'auto' }}
                        display={{ _: '-webkit-box', md: 'none' }}
                        css={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '-webkit-line-clamp':
                                '2' /* number of lines to show */,
                            lineClamp: '2',
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {sponsor}
                    </View>
                </View>
                <View
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight="600"
                    color={colors.neutral[100]}
                    display={{ _: 'none', sm: '-webkit-box' }}
                    css={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ' -webkit-line-clamp':
                            '2' /* number of lines to show */,
                        lineClamp: '2',
                        '-webkit-box-orient': 'vertical',
                    }}
                >
                    {body}
                </View>
                <View
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight="600"
                    color={colors.neutral[100]}
                    display={{ _: '-webkit-box', sm: 'none' }}
                    css={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ' -webkit-line-clamp':
                            '3' /* number of lines to show */,
                        lineClamp: '3',
                        '-webkit-box-orient': 'vertical',
                    }}
                >
                    {body}
                </View>
            </View>
            <View
                fontSize="16px"
                lineHeight="18px"
                fontWeight="600"
                color={colors.primary[60]}
                className="link-element"
                display="flex"
                justifyContent={{ _: 'flex-end', md: 'start' }}
                position={{ _: 'absolute', md: 'inherit' }}
                bottom={{ _: '16px', md: 'inherit' }}
                right={{ _: '16px', md: 'inherit' }}
            >
                View event
                <Icon
                    pt="1px"
                    pl="6px"
                    variant="chevron_right_16"
                    outlineColor={colors.primary[60]}
                />
            </View>
        </View>
    );
};

export const CalendarEvent = ({
    month,
    day,
    time,
    title,
    sponsor,
    body,
    url,
    ...others
}) => {
    return (
        <View
            height="fit-content"
            width={{ _: 'fit-content', md: '482px', lg: '466px' }}
            position="relative"
            css={{
                cursor: 'pointer',
                ':hover': {
                    '.link-element': {
                        textDecoration: 'underline',
                    },
                    '.link-body': {
                        textDecoration: 'none',
                    },
                },
                '*': {
                    '-webkit-touch-callout': 'none' /* iOS Safari */,
                    WebkitUserSelect: 'none' /* Safari */,
                    '-khtml-user-select': 'none' /* Konqueror HTML */,
                    MozUserSelect: 'none' /* Old versions of Firefox */,
                    msUserSelect: 'none' /* Internet Explorer/Edge */,
                    userSelect: 'none',
                },
            }}
            {...others}
        >
            <View
                boxShadow={{ _: '0 4px 8px rgb(53 53 53 / 20%)', md: 'none' }}
                as="a"
                href={url}
                target="_self"
                width={{ _: '242px', sm: '276px', md: '100%' }}
                height={{ _: '282px', sm: '256px', md: '172px', lg: '172px' }}
                bg={colors.neutral[0]}
                display={{ _: 'inherit', md: 'flex' }}
                flexDirection={{ _: 'column', md: 'inherit' }}
                borderRadius="10px"
                overflow="hidden"
                className="link-body"
            >
                <View
                    maxWidth={{ md: '102px' }}
                    flex={{ md: '2', lg: '1' }}
                    bg={colors.secondary[10]}
                    px={{ _: '0px', lg: '5px' }}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                >
                    <Date month={month} date={day} time={time} />
                </View>
                <View height={{ _: '100%' }} flex={{ md: '5', lg: '4' }}>
                    <Body title={title} sponsor={sponsor} body={body} />
                </View>
            </View>
        </View>
    );
};
