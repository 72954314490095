import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider as PAGE_CONTEXT } from '../../contexts/PageContext';
import HomePage from './HomePage';
// All boilerplate cut/paste stuff
// eslint-disable-next-line no-undef
const props = globalPageProps || {};
ReactDOMClient.hydrateRoot(
    document.getElementById('root'),
    <PAGE_CONTEXT PAGE_PROPS={props.PAGE_PROPS}>
        <HomePage {...props} />
    </PAGE_CONTEXT>
);
