import React from 'react';
import { View, colors, Text, Grid, GridColumn } from '@fairygodboss/ui';
import { FooterLinksListSection } from './components/FooterLinksListSection';
import { FooterBrandAndSocialsSection } from './components/FooterBrandAndSocialsSection';

export const Footer = () => {
    return (
        <View data-qa="footer-section" position="relative">
            <View
                zIndex="-1"
                position="absolute"
                backgroundColor={colors.secondary[70]}
                width="100vw"
                height="100%"
                css={{
                    transform: 'translateX(-50%)',
                    marginLeft: '50%',
                }}
            />
            <Grid
                width="100%"
                pt={{
                    _: '40px',
                    xs: '40px',
                    sm: '40px',
                    md: '72px',
                    lg: '72px',
                    xl: '72px',
                }}
            >
                <GridColumn
                    size={{
                        _: 3,
                        xs: 12,
                        sm: 12,
                        md: 3,
                        lg: 3,
                        xl: 3,
                    }}
                    minWidth={{
                        _: 'none',
                        xs: 'none',
                        sm: 'none',
                        md: '240px',
                        lg: '240px',
                        xl: '240px',
                    }}
                >
                    <FooterBrandAndSocialsSection />
                </GridColumn>
                <GridColumn
                    minWidth={{
                        _: 'none',
                        xs: 'none',
                        sm: 'none',
                        md: '157px',
                        lg: 'none',
                        xl: 'none',
                    }}
                    size={{
                        _: 6,
                        xs: 6,
                        sm: 3,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                    px="16px"
                >
                    <FooterLinksListSection
                        sectionTitle={{
                            value: 'Fairygodboss',
                            link: '/',
                        }}
                        sectionItems={[
                            {
                                value: 'Community Guidelines',
                                link: '/articles/community-guidelines',
                            },
                            {
                                value: 'Submissions & Editorial Guidelines',
                                link:
                                    '/articles/submissions-editorial-guidelines',
                            },
                            {
                                value: 'Privacy Policy',
                                link: '/privacy-policy',
                            },
                            {
                                value: 'Terms & Conditions',
                                link: '/terms-and-conditions',
                            },
                            {
                                value: 'The Muse',
                                link: 'https://www.themuse.com',
                                openInNewTab: true,
                            },
                        ]}
                    />
                </GridColumn>
                <GridColumn
                    minWidth={{
                        _: 'none',
                        xs: 'none',
                        sm: 'none',
                        md: '157px',
                        lg: 'none',
                        xl: 'none',
                    }}
                    size={{
                        _: 6,
                        xs: 6,
                        sm: 3,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                    px="16px"
                >
                    <FooterLinksListSection
                        sectionTitle={{
                            value: 'Advice',
                            link: '/advice',
                        }}
                        sectionItems={[
                            {
                                value: 'Community',
                                link: '/community/feed',
                            },
                            {
                                value: 'Articles',
                                link: '/articles',
                            },
                            {
                                value: 'Podcasts',
                                link: '/community-podcasts',
                            },
                            {
                                value: 'Research',
                                link: '/research',
                            },
                            {
                                value: 'Pregnancy Week by Week',
                                link: '/pregnancy-week-by-week',
                            },
                            {
                                value: 'Browse Discussions',
                                link:
                                    '/career-topics/browse-community-discussions-by-date',
                            },
                        ]}
                    />
                </GridColumn>
                <GridColumn
                    minWidth={{
                        _: 'none',
                        xs: 'none',
                        sm: 'none',
                        md: '157px',
                        lg: 'none',
                        xl: 'none',
                    }}
                    size={{
                        _: 6,
                        xs: 6,
                        sm: 3,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                    px="16px"
                >
                    <FooterLinksListSection
                        sectionTitle={{
                            value: 'Jobs',
                            link: '/jobs/find',
                        }}
                        sectionItems={[
                            {
                                value: 'Browse All Jobs',
                                link: '/jobs/find?keywords=',
                            },
                            {
                                value: 'Remote Jobs',
                                link: '/jobs/find?filters_remote=1&location=',
                            },
                            {
                                value: 'Company Reviews',
                                link: '/company-reviews',
                            },
                            {
                                value: 'Best Companies for Women ',
                                link: '/best-companies-for-women-2023',
                            },
                            {
                                value: 'Find Jobs at Sponsored Companies ',
                                link: '/company-list',
                            },
                        ]}
                    />
                </GridColumn>
                <GridColumn
                    minWidth={{
                        _: 'none',
                        xs: 'none',
                        sm: 'none',
                        md: '157px',
                        lg: 'none',
                        xl: 'none',
                    }}
                    size={{
                        _: 6,
                        xs: 6,
                        sm: 3,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    }}
                    mt={{
                        _: '0px',
                        xs: '0px',
                        sm: '0px',
                        md: '0px',
                        lg: '0px',
                        xl: '0px',
                    }}
                    px="16px"
                >
                    <FooterLinksListSection
                        sectionTitle={{
                            value: 'Events',
                            link: '/events',
                        }}
                        sectionItems={[
                            {
                                value: 'Events Calendar',
                                link: '/events?eventStatus=upcoming',
                            },
                            {
                                value: 'Virtual Career Fairs',
                                link:
                                    '/events?eventStatus=upcoming&eventType=Virtual%20Recruiting%20Event',
                            },
                        ]}
                    />
                    <FooterLinksListSection
                        sectionTitle={{
                            value: 'Employers',
                            link: '/employers',
                        }}
                        sectionItems={[
                            {
                                value: 'Partner with Us',
                                link: '/employers',
                            },
                            {
                                value: 'Client Login',
                                link: '/employers/dashboard',
                            },
                        ]}
                    />
                </GridColumn>
            </Grid>
            {/* Logo and Socials Block */}
            <View
                display="flex"
                css={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Text
                    color={colors.white}
                    css={{
                        lineHeight: '12px',
                        fontSize: '10px',
                        fontWeight: '400',
                        WebkitUserSelect: 'none' /* Safari */,
                        MozUserSelect: 'none' /* Firefox */,
                        msUserSelect: 'none' /* IE10+/Edge */,
                        userSelect: 'none' /* Standard */,
                    }}
                >
                    &#169;&#160;{new Date().getFullYear()}&#160;Fairygodboss
                </Text>
            </View>
        </View>
    );
};
