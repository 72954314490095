import React from 'react';

export const RadioMic = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="29"
            viewBox="0 0 22 29"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0C7.68629 0 5 2.68629 5 6H9C9.55228 6 10 6.44772 10 7C10 7.55228 9.55228 8 9 8H5V11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H5V14C5 17.3137 7.68629 20 11 20C14.3137 20 17 17.3137 17 14V6C17 2.68629 14.3137 0 11 0ZM2 14C2 13.4477 1.55228 13 1 13C0.447715 13 0 13.4477 0 14C0 19.5304 4.38263 24.428 10 24.9534V28C10 28.5523 10.4477 29 11 29C11.5523 29 12 28.5523 12 28V24.9534C17.6174 24.428 22 19.5304 22 14C22 13.4477 21.5523 13 21 13C20.4477 13 20 13.4477 20 14C20 18.7769 15.9572 23 11 23C6.04278 23 2 18.7769 2 14Z"
                fill="#982EA9"
            />
        </svg>
    );
};
