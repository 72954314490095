import React from 'react';
import { View, colors, Link } from '@fairygodboss/ui';
import Convincer from './components/Convincer';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';

export const ArticlesAndResearchBlock = ({
    firstHeading = 'Straight to your inbox',
    firstSubheading = 'Career trends, discussions, and new articles daily. Sign up to get the latest info on topics you care about.',
    secondHeading = 'Get all the insights',
    secondSubheading = 'Stay up to date on the latest research on what women are experiencing in the workforce.',
}) => {
    const onReadMoreClick = () => {
        window.open('/research', '_self');
    };

    return (
        <View
            className="homepage-section"
            data-qa="articles-research-block"
            height={{
                _: 'inherit',
            }}
            position="relative"
            backgroundColor={colors.secondary[10]}
        >
            <View
                zIndex="-1"
                position="absolute"
                backgroundColor={colors.secondary[10]}
                width="100vw"
                height="100%"
                css={{
                    transform: 'translateX(-50%)',
                    marginLeft: '50%',
                }}
            />
            <View
                height="100%"
                px={{ _: '16px', sm: '89px', md: '24px', lg: '44px' }}
                py={{ _: '48px', sm: '64px', md: '56px', lg: '88px' }}
            >
                <View
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={{ _: 'column', md: 'row' }}
                >
                    <Convincer
                        flex={{ _: 'inherit', md: 5, lg: 'inherit' }}
                        alignSelf={{
                            _: 'inherit',
                            xs: 'center',
                            sm: 'center',
                            md: 'inherit',
                        }}
                        maxWidth={{ _: null, xs: null, sm: '422px' }}
                        heading={firstHeading}
                        subheading={firstSubheading}
                        buttonText="Sign up"
                        buttonWidth={111}
                        buttonColor={colors.secondary[60]}
                        buttonHoverColor={colors.secondary[70]}
                        redirectUrl="/community/feed"
                    />
                    <View
                        display={{ _: 'flex', sm: 'inherit' }}
                        justifyContent={{
                            _: 'center',
                            md: 'flex-end',
                            lg: 'flex-end',
                            xl: 'flex-end',
                        }}
                        flex={{ sm: 'inherit', md: 6, lg: 'inherit' }}
                    >
                        <View
                            alignSelf={{ _: 'center', md: 'auto' }}
                            overflow="hidden"
                            display={{
                                _: 'block',
                                sm: 'none',
                                xs: 'none',
                                md: 'block',
                                lg: 'block',
                            }}
                            maxWidth="716px"
                        >
                            <img
                                src={buildImagePath(
                                    './Homepage/images/StylizedArticle.png',
                                    'img',
                                    {
                                        img: 'c_scale,f_auto,q_auto',
                                    }
                                )}
                                style={{ width: '100%', height: 'auto' }}
                                alt="A preview of an article on 4 Ways Successful Women Network When they Don't Need a New Job and FGB users commenting"
                            />
                        </View>
                        <View
                            alignSelf={{ _: 'center', md: 'auto' }}
                            maxWidth={{ _: '400px', sm: '500px' }}
                            display={{
                                _: 'block',
                                xs: 'block',
                                sm: 'block',
                                md: 'none',
                                lg: 'none',
                                xl: 'none',
                            }}
                            mt={{
                                _: '54px',
                                xs: '24px',
                                sm: '24px',
                            }}
                        >
                            <img
                                src={buildImagePath(
                                    './Homepage/images/StylizedArticleSmall.png',
                                    'img',
                                    {
                                        img: 'c_scale,f_auto,q_auto',
                                    }
                                )}
                                style={{ width: '100%', height: 'auto' }}
                                alt="A preview of an article on 4 Ways Successful Women Network When they Don't Need a New Job and FGB users commenting"
                            />
                        </View>
                    </View>
                </View>
                <View
                    display="flex"
                    alignItems={{
                        _: 'center',
                        sm: 'baseline',
                        md: 'center',
                        lg: 'center',
                    }}
                    justifyContent="space-between"
                    flexDirection={{ _: 'column-reverse', md: 'row' }}
                >
                    <View
                        flex={{ _: 'inherit', md: 6, lg: 'inherit' }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                    >
                        <View
                            display={{
                                _: 'block',
                                sm: 'none',
                                xs: 'none',
                                md: 'block',
                                lg: 'block',
                            }}
                            width={{
                                _: '100%',
                                sm: '422px',
                                md: '100%',
                                lg: '564px',
                            }}
                            maxWidth="564px"
                            minWidth={{
                                _: null,
                                sm: null,
                                md: 417,
                                lg: null,
                            }}
                            mr={{
                                lg: '70px',
                                xl: '70px',
                            }}
                            height={{
                                _: '100%',
                                sm: '422px',
                                md: '100%',
                                lg: '556px',
                            }}
                            as="a"
                            href="/research"
                            target="_self"
                        >
                            <img
                                src={buildImagePath(
                                    './Homepage/images/DataGraphics.png',
                                    'img',
                                    {
                                        img: 'c_scale,f_auto,q_auto',
                                    }
                                )}
                                style={{ width: '90%', height: 'auto' }}
                                alt="Read our latest research on Why More Women of Color are Ready to Leave the Workforce"
                            />
                        </View>
                        <View
                            alignSelf={{ _: 'center', md: 'auto' }}
                            maxWidth={{ _: '400px', sm: '500px' }}
                            display={{
                                _: 'block',
                                xs: 'block',
                                sm: 'block',
                                md: 'none',
                                lg: 'none',
                                xl: 'none',
                            }}
                            height={{
                                _: '100%',
                                sm: '422px',
                                md: '100%',
                                lg: '556px',
                            }}
                            mt={{
                                _: '54px',
                                xs: '24px',
                                sm: '24px',
                            }}
                            mr={{
                                _: '16px',
                                xs: '24px',
                                sm: '16px',
                            }}
                            as="a"
                            href="/research"
                            target="_self"
                        >
                            <img
                                src={buildImagePath(
                                    './Homepage/images/DataGraphicsSmall.png',
                                    'img',
                                    {
                                        img: 'c_scale,f_auto,q_auto',
                                    }
                                )}
                                style={{ width: '100%', height: 'auto' }}
                                alt="A preview of an article on 4 Ways Successful Women Network When they Don't Need a New Job and FGB users commenting"
                            />
                        </View>
                    </View>
                    <View
                        display={{ _: 'none', md: 'flex', lg: 'none' }}
                        flex={{ _: 'inherit', md: 1, lg: 'inherit' }}
                    />
                    <Convincer
                        width={{
                            _: '100%',
                            sm: '424px',
                            md: '100%',
                            lg: '564px',
                        }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                        maxWidth={{
                            _: null,
                            xs: null,
                            sm: '422px',
                            md: '600px',
                            lg: '600px',
                            xl: '600px',
                        }}
                        flex={{ _: 'inherit', md: 5, lg: 'inherit' }}
                        heading={secondHeading}
                        subheading={secondSubheading}
                        buttonText="Read More"
                        buttonWidth={137}
                        buttonColor={colors.secondary[60]}
                        buttonHoverColor={colors.secondary[70]}
                        onClickEvent={onReadMoreClick}
                    >
                        <Link
                            href="/research"
                            variant="standalone"
                            target="_self"
                            icon
                            lineHeight="24px"
                            css={{
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    color: `${colors.primary[60]} !important`,
                                    'div > svg': {
                                        path: {
                                            fill: colors.primary[60],
                                        },
                                    },
                                },
                                '&:active': {
                                    color: `${colors.primary[60]} !important`,
                                    'div > svg': {
                                        path: {
                                            fill: colors.primary[60],
                                        },
                                    },
                                },
                                '&:visited': {
                                    color: `${colors.primary[60]} !important`,
                                    'div > svg': {
                                        path: {
                                            fill: colors.primary[60],
                                        },
                                    },
                                },
                            }}
                            display={{
                                _: 'none',
                                xs: 'inline-flex',
                                sm: 'none',
                                md: 'none',
                            }}
                            aria-label="learn more"
                        >
                            Read more
                        </Link>
                    </Convincer>
                </View>
            </View>
        </View>
    );
};
