import React from 'react';
import { View, Heading, Text, colors } from '@fairygodboss/ui';
import { StayInformed, NewJob, ConnectAndSupport } from './assets/svgs';
import { AuthWrapper } from './components/AuthWrapper';

const SupportingValue = ({
    svg,
    heading,
    subheading,
    invert,
    isLink,
    url,
    ...others
}) => {
    const SVGButton = () => {
        return (
            <View
                onClick={(e) => {
                    e?.preventDefault();
                    if (isLink) {
                        window.open('/jobs/find?keywords=', '_self');
                    }
                }}
                border="none"
                background="none"
                fontFamily="Open Sans"
                as="button"
                display={{ _: 'flex', sm: 'inline-block' }}
                {...others}
            >
                <View
                    width={{ _: '250px', sm: '450px', md: '265px' }}
                    pt={{ _: '8px', sm: '0px' }}
                    justify-content="center"
                    display="flex"
                    flexDirection={{
                        _: 'column',
                        sm: invert ? 'row-reverse' : 'row',
                        md: 'column',
                    }}
                    alignItems="center"
                    css={{
                        cursor: 'pointer',
                        '.supporting-values-svg': {
                            transition: 'all 0.2s',
                        },
                        ':hover': {
                            h2: { color: colors.primary[70] },
                            '.supporting-values-svg': {
                                transition: 'all 0.2s',
                                transform: 'scale(1.2)',
                            },
                        },
                    }}
                >
                    <View
                        className="supporting-values-svg"
                        width={{ _: '156px', sm: '178px' }}
                    >
                        {svg()}
                    </View>
                    <View
                        width={{
                            _: '100%',
                            sm: '250px',
                            md: invert ? '212px' : '255px',
                        }}
                        pl={{ _: '0px', sm: '24px', md: '0px' }}
                        textAlign={{ _: 'center', sm: 'left', md: 'center' }}
                    >
                        <Heading
                            pt={{ _: '16px', sm: '0px', md: '10px' }}
                            color={colors.primary[60]}
                            fontSize="26px"
                            lineHeight="38px"
                            variant="headingLg"
                            minWidth={{ _: '242px', md: 'inherit' }}
                            as="h2"
                            id="heading-stuff"
                        >
                            {heading}
                        </Heading>
                        <Text
                            color={colors.neutral[100]}
                            width={{
                                _: '100%',
                                sm: invert ? '207px' : '242px',
                                md: '100%',
                            }}
                            pl={{ _: '0px' }}
                            mt="8px"
                            lineHeight="26px"
                            variant="bodyMd"
                        >
                            {subheading}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    return isLink ? (
        <SVGButton />
    ) : (
        <AuthWrapper
            component={<SVGButton />}
            redirectUrl="/community/feed"
            loginTrigger="home-page"
        />
    );
};

export const SupportingValueProps = () => {
    return (
        <View
            className="homepage-section"
            data-qa="suporting-value-props"
            // pt={{ _: '0px', md: '84px' }}
            height={{ _: '1141px', sm: '800px', md: '475px' }}
            display="flex"
            justifyContent="space-around"
            px="34px"
            alignItems={{ _: 'center', md: 'center' }}
            flexDirection={{ md: 'row', _: 'column' }}
            css={{
                '*': {
                    ':focus': {
                        outline: 'none',
                    },
                    WebkitTapHighlightColor: 'transparent',
                },
            }}
        >
            <SupportingValue
                mt={{ _: '0px', md: '-16px' }}
                pt={{ _: '8px', sm: '16px', md: '0px' }}
                svg={() => <StayInformed />}
                heading="Stay informed"
                subheading="Keep on top of trending career topics with daily articles, discussions, and research."
            />
            <SupportingValue
                isLink
                width={{ _: '255px', sm: '450px', md: '265px' }}
                svg={() => <NewJob />}
                invert
                heading="Find a new job"
                subheading="Explore opportunities at companies who want to hire more women like you."
            />
            <SupportingValue
                svg={() => <ConnectAndSupport />}
                heading="Connect & support"
                subheading="Get your hard-to-ask questions answered in a safe, authentic, and positive environment."
            />
        </View>
    );
};
