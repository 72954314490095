import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { View, Button, Heading, Text, colors } from '@fairygodboss/ui';
import { Logo } from './assets/svgs';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';
import { AuthWrapper } from './components/AuthWrapper';

const CTA = () => (
    <>
        <View
            width={{ _: '131px', sm: '212px' }}
            pt="6px"
            as="a"
            href="/homepagev2"
            aria-label="FairyGodBoss"
            target="_self"
        >
            <Logo />
        </View>
        <View display="flex">
            <AuthWrapper
                component={
                    <Button
                        data-qa="main-value-props-sign-in"
                        mr={{ _: '0px', md: '16px' }}
                        height="48px"
                        px="26px"
                        size="large"
                    >
                        Sign in
                    </Button>
                }
                redirectUrl="/community/feed"
                loginTrigger="home-page-sign-in"
            />
            <AuthWrapper
                component={
                    <Button
                        data-qa="main-value-props-get-started"
                        display={{ md: 'initial', _: 'none' }}
                        variant="secondary"
                        size="large"
                        width="140px"
                        css={{
                            borderRadius: '4px',
                            border: '1px solid',
                            color: colors.primary[60],
                            borderColor: colors.primary[60],
                        }}
                    >
                        Get started
                    </Button>
                }
                redirectUrl="/community/feed"
                loginTrigger="home-page"
            />
        </View>
    </>
);

export const JoinCommunityButton = forwardRef((props, ref) => {
    const ButtonComponent = () => (
        <Button
            data-qa="main-value-props-join-community-button"
            ref={ref}
            mt={{ _: '15px', sm: '32px', md: '32px' }}
            mb={{ _: '22px', sm: '20px', md: '0px' }}
            maxWidth={{ _: '227px', sm: '225px' }}
            height="56px"
            px="40px"
            py="18px"
            size="medium"
            fontSize="16px"
            lineHeight="20px"
            borderRadius={{ _: '56px', sm: '10px' }}
            boxShadow={{
                _: '0px 12px 32px rgba(53, 53, 53, 0.4)',
                sm: '0px 24px 40px rgba(53, 53, 53, 0.16)',
            }}
            css={{
                span: {
                    overflow: 'inherit',
                },
            }}
        >
            Join the community
        </Button>
    );

    return (
        <AuthWrapper
            component={<ButtonComponent />}
            redirectUrl="/community/feed"
            loginTrigger="home-page"
        />
    );
});

const BackgroundImage = ({ setImgHeight }) => {
    const smallRef = useRef();
    const largeRef = useRef();
    const xsRef = useRef();

    const handleResize = useCallback(() => {
        window.requestAnimationFrame(() => {
            const height =
                xsRef?.current?.offsetHeight ||
                smallRef.current?.offsetHeight ||
                largeRef.current?.offsetHeight;
            if (height) {
                setImgHeight(height);
            }
        });
    }, [setImgHeight, xsRef?.current, smallRef?.current, largeRef?.current]);
    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window?.addEventListener('resize', handleResize);
        return () => window?.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const styles = {
        zIndex: '-1',
        left: '0',
        position: 'absolute',
        minWidth: '1248px',
        width: { _: 'auto', lg: '100vw' },
        margin: '0 auto',
        height: { _: '771px', md: '800px', sm: '852px' },
        overflow: 'hidden',
        overflowX: 'hidden',
        maxWidth: '100%',
        marginLeft: { _: '84%', md: '67%', lg: '67%' },
    };
    return (
        <>
            <View
                display={{ _: 'none', lg: 'inherit' }}
                textAlign="center"
                css={{
                    transform: 'translateX(-67%)',
                }}
                {...styles}
                minWidth="1230px"
            >
                <img
                    src={buildImagePath(
                        './Homepage/images/GalvanizeEventsPhoto.jpeg',
                        'img',
                        {
                            img: 'c_scale,f_auto,q_auto,w_2880',
                        }
                    )}
                    alt="Logo"
                    width="100vw"
                    ref={largeRef}
                    style={{
                        overflow: 'hidden',
                        minWidth: ' 1336px',
                        width: '100vw',
                        marginTop: '0px',
                    }}
                />
            </View>
            <View
                display={{ _: 'none', md: 'inherit', lg: 'none' }}
                css={{
                    transform: 'translateX(-67%)',
                }}
                {...styles}
            >
                <img
                    src={buildImagePath(
                        './Homepage/images/GalvanizeEventsPhoto.jpeg',
                        'img',
                        {
                            img: 'c_scale,f_auto,q_auto,w_2880',
                        }
                    )}
                    alt="Logo"
                    width="100vw"
                    ref={smallRef}
                    style={{
                        overflow: 'hidden',
                        minWidth: ' 1336px',
                        width: '100vw',
                    }}
                />
            </View>
            <View
                display={{ _: 'none', sm: 'inherit', md: 'none' }}
                css={{
                    transform: 'translateX(-57%)',
                }}
                {...styles}
            >
                <img
                    src={buildImagePath(
                        './Homepage/images/GalvanizeEventsPhoto.jpeg',
                        'img',
                        {
                            img: 'c_scale,f_auto,q_auto,w_2880',
                        }
                    )}
                    alt="Logo"
                    ref={xsRef}
                    width="1430px"
                    style={{
                        overflow: 'hidden',
                        width: '1430px',
                        objectFit: 'scale-down',
                    }}
                />
            </View>
            <View
                display={{ _: 'inherit', sm: 'none' }}
                css={{
                    transform: 'translateX(-57%)',
                }}
                {...styles}
            >
                <img
                    src={buildImagePath(
                        './Homepage/images/GalvanizeEventsPhoto.jpeg',
                        'img',
                        {
                            img: 'c_scale,f_auto,q_auto,w_2880',
                        }
                    )}
                    alt="Logo"
                    width="1248px"
                    style={{
                        overflow: 'hidden',
                        objectFit: 'scale-down',
                    }}
                />
            </View>
        </>
    );
};

export const MainValueProps = forwardRef(
    (
        {
            header = ' Achieve your career goals',
            subheader = 'Join the largest online career community for women to find jobs, advice, and support. Membership is free, always.',
        },
        ref
    ) => {
        const [imgHeight, setImgHeight] = useState(771);

        return (
            <View
                className="homepage-section"
                data-qa="main-value-props"
                height={{ _: 'auto', md: '800px' }}
                position={{ _: 'relative', lg: 'initial' }}
                overflow="hidden"
                background={{
                    md: 'none',
                    _:
                        'linear-gradient(0.22deg, rgba(23, 18, 27, 0.2) 15.79%, rgba(23, 18, 27, 0.2) 15.79%, rgba(33, 27, 36, 0) 78.65%),linear-gradient(359.53deg, rgba(27, 8, 30, 0.5) 23.5%, rgba(53, 20, 61, 0.065) 52.54%, rgba(57, 23, 66, 0) 75.22%),linear-gradient(357.09deg, rgba(34, 11, 37, 0.174) 13.28%, rgba(240, 215, 255, 0.03) 50.33%)',
                }}
            >
                <View
                    position="absolute"
                    left="0"
                    width="100vw"
                    display={{ _: 'none', lg: 'flex' }}
                    justifyContent="center"
                    pt="56px"
                    maxWidth="100%"
                >
                    <View
                        display="flex"
                        width={{ _: '1210px', xl: '100vw' }}
                        px={{ _: '0', xl: '142px' }}
                        justifyContent="space-between"
                        alignItems="center"
                        zIndex="1"
                    >
                        <CTA />
                    </View>
                </View>
                <View
                    opacity="0.7"
                    zIndex="0"
                    position="absolute"
                    left="0"
                    width="100vw"
                    maxWidth="100%"
                    height={{
                        _: '-webkit-fill-available',
                        sm: '842px',
                        md: `${imgHeight}px`,
                    }}
                    background={{
                        _:
                            'linear-gradient(180deg, #C4C4C4 0%, rgba(255, 255, 255, 0) 15.88%);',
                        sm:
                            'linear-gradient(180deg, #C4C4C4 0%, rgba(255, 255, 255, 0) 15.31%)',
                        md:
                            'linear-gradient(180deg, #C4C4C4 0%, rgba(255, 255, 255, 0) 15.31%)',
                    }}

                />
                <View
                    opacity="0.7"
                    zIndex="0"
                    position="absolute"
                    left="0"
                    width="100vw"
                    maxWidth="100%"
                    minWidth="1336px"
                    maxHeight="800px"
                    height={{
                        _: '-webkit-fill-available',
                        sm: '842px',
                        md: `${imgHeight}px`,
                    }}
                    background={{
                        _:
                            'linear-gradient(359.81deg,#080409 50.46%,rgba(18,2,20,0) 102.31%)',
                        sm:
                            'linear-gradient(359.81deg,#080409 50.46%,rgba(18,2,20,0) 102.31%)',
                        md:
                            'linear-gradient(359.81deg, #080409 25.46%, rgba(18, 2, 20, 0) 73.31%)',
                    }}
                    css={{
                        aspectRatio: '225/122',
                    }}
                />
                <BackgroundImage
                    setImgHeight={setImgHeight}
                />
                <View
                    px={{ _: '16px', sm: '24px' }}
                    position="relative"
                    height="100%"
                >
                    <View
                        pt={{ md: '56px', _: '40px' }}
                        display={{ _: 'flex', lg: 'none' }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <CTA />
                    </View>
                    <View
                        display="flex"
                        flexDirection="column"
                        minWidth="318px"
                        pt={{ _: '261px', md: '184px', lg: '376px' }}
                        textAlign={{ _: 'center', sm: 'inherit' }}
                        css={{
                            '@media screen and (min-width: 1240px) and (max-width: 1346px)': {
                                'padding-top': '323px',
                            },
                        }}
                    >
                        <Heading
                            maxWidth={{ _: '486px', md: '564px' }}
                            fontSize={{ _: '50px', sm: '62px' }}
                            variant="heading2xl"
                            color={{ _: colors.secondary[5] }}
                            lineHeight={{ _: '64px', sm: '72px' }}
                            alignSelf={{ _: 'center', sm: 'auto' }}
                        >
                            {header}
                        </Heading>
                        <Heading
                            as="h2"
                            variant="headingSm"
                            fontSize={{ _: '16px', sm: '20px' }}
                            pt={{ _: '24px', md: '24px' }}
                            maxWidth={{ _: '389px', sm: '440px', md: '466px' }}
                            color={{ _: colors.secondary[5] }}
                            alignSelf={{ _: 'center', sm: 'auto' }}
                        >
                            {subheader}
                        </Heading>
                        <View>
                            <JoinCommunityButton ref={ref} />
                        </View>
                    </View>
                </View>
            </View>
        );
    }
);
