import React from 'react';
import { View, colors } from '@fairygodboss/ui';
import { buildImagePath } from '../../../../components/JobComponents/Company/Image/logo';

const SpeakerImage = ({
    source = null,
    url,
    name,
    speakerPosition,
    speakerAltText,
    xsMode,
    ...props
}) => {
    return (
        <View
            css={{
                flexGrow: '1',
                transition: 'all 300ms ease-in-out',
                height: '282px',
                width: xsMode ? '242px' : '276px',
                overflow: 'hidden',
                borderRadius: '20px',
                position: 'relative',
                cursor: 'pointer',
            }}
            onClick={() => {
                window.open(url, '_self');
            }}
            {...props}
        >
            <img
                src={buildImagePath(source, 'img', {
                    img: 'c_scale,f_auto,q_auto',
                })}
                style={{
                    width: '100%',
                    position: 'absolute',
                }}
                alt={speakerAltText}
            />
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    top: '50%',
                    height: '50%',
                    background:
                        'linear-gradient(179.96deg, rgba(19, 31, 53, 0) 4.2%, rgba(18, 29, 49, 0.16) 4.2%, rgba(19, 31, 53, 0) 4.2%, rgba(11, 17, 29, 0.75) 89.59%)',
                }}
            />
            <View
                css={{
                    position: 'absolute',
                    bottom: '0px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                }}
            >
                <View
                    css={{
                        margin: '0px 16px 12px 16px',
                        marginTop: '0px',
                        alignSelf: 'flex-start',

                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '-0.4px',
                        textAlign: 'left',
                    }}
                >
                    <View
                        css={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '20px',
                            letterSpacing: '-0.4px',
                            textAlign: 'left',
                            color: colors.neutral[0],
                        }}
                    >
                        {name}
                    </View>
                    <View
                        css={{
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '18px',
                            letterSpacing: '-0.4px',
                            textAlign: 'left',
                            color: colors.neutral[0],
                        }}
                    >
                        {speakerPosition}
                    </View>
                </View>
            </View>
        </View>
    );
};

export const RadioSpeakerImagesSmall = ({
    speaker1Name = 'Wanda Bryant Hope',
    speaker1position = 'Chief DEI Officer, Johnson & Johnson',
    speaker1AltText = 'A headshot of Wanda Bryant Hope, Cheif DEI Officer at Johnson and Johnson. Click to hear her podcast.',
    speaker1url = '/community-podcast/wPS__H8AV/fairygodboss-radio-wanda-bryant-hope',
    speaker1ImgUrl = './Homepage/images/RadioSpeaker1.jpeg',
    speaker2Name = 'Tiffany Dufu',
    speaker2position = 'Founder and CEO, The Cru',
    speaker2AltText = 'A headshot of Tiffany Dufu, the Founder and CEO, The Cru. Click to hear her podcast.',
    speaker2url = '/community-podcast/Ee3I1EAxQ/fairygodboss-radio-tiffany-dufu-founder',
    speaker2ImgUrl = './Homepage/images/RadioSpeaker2.jpeg',
    speaker3Name = 'Katie Warchol',
    speaker3position = 'Financial Advisor, Edward Jones',
    speaker3AltText = 'A headshot of Katie Warchol, Financial Advisor at Edward Jones. Click to hear her podcast.',
    speaker3url = '/community-podcast/dpLWYwxQm/fairygodboss-radio-katie-warchol-financial',
    speaker3ImgUrl = './Homepage/images/RadioSpeaker3.jpeg',
    xsMode = false,
    ...otherProps
}) => {
    return (
        <View
            css={{
                overflowX: xsMode ? 'auto' : 'hidden',
            }}
            marginTop={{ _: '24px', sm: '32px' }}
            {...otherProps}
        >
            <View
                css={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginBottom: '24px',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <SpeakerImage
                    source={speaker1ImgUrl}
                    url={speaker1url}
                    name={speaker1Name}
                    speakerPosition={speaker1position}
                    speakerAltText={speaker1AltText}
                    xsMode={xsMode}
                    ml={xsMode ? '16px' : '0px'}
                />
                <SpeakerImage
                    source={speaker2ImgUrl}
                    url={speaker2url}
                    name={speaker2Name}
                    speakerPosition={speaker2position}
                    speakerAltText={speaker2AltText}
                    xsMode={xsMode}
                    marginLeft="16px"
                />
                {xsMode && (
                    <SpeakerImage
                        source={speaker3ImgUrl}
                        url={speaker3url}
                        name={speaker3Name}
                        speakerPosition={speaker3position}
                        speakerAltText={speaker3AltText}
                        xsMode={xsMode}
                        marginLeft="16px"
                    />
                )}
            </View>
        </View>
    );
};
