import React from 'react';
import { View, Button, Heading, Text, colors } from '@fairygodboss/ui';
import { AuthWrapper } from './AuthWrapper';

const Convincer = ({
    heading,
    subheading,
    buttonText,
    buttonWidth,
    buttonColor = null,
    buttonHoverColor = null,
    buttonDisplay = null,
    buttonMarginTop = null,
    internalPadding = '0px',
    useFont18Body = false,
    redirectUrl,
    onClickEvent,
    children,
    ...others
}) => {
    const ButtonComponent = () => {
        return (
            <Button
                data-qa="main-value-props-convincer-button"
                onPress={onClickEvent}
                py="14px"
                display={buttonDisplay || { _: 'none', sm: 'flex' }}
                size="large"
                mt={buttonMarginTop || '32px'}
                mb={{ _: '16px', md: '0px' }}
                letterSpacing="-0.4px"
                alignItems="center"
                height="48px"
                width={buttonWidth}
                css={{
                    span: {
                        overflow: 'inherit',
                    },
                    backgroundColor: buttonColor,
                    borderColor: buttonColor,
                    '&:hover': {
                        backgroundColor: buttonHoverColor,
                        borderColor: buttonHoverColor,
                    },
                }}
            >
                {buttonText}
            </Button>
        );
    };

    return (
        <View {...others}>
            <Heading
                variant="headingLg"
                as="h3"
                fontSize={{ sm: '42px', md: '52px' }}
                lineHeight={{ sm: '56px', md: '70px' }}
                color={colors.neutral[100]}
                px={internalPadding}
            >
                {heading}
            </Heading>
            <Text
                fontSize={
                    useFont18Body
                        ? { xs: '18px', sm: '18px', md: '18px' }
                        : { sm: '16px', md: '18px' }
                }
                lineHeight={{ sm: '26px', md: '30px' }}
                pt="24px"
                color={colors.neutral[100]}
                px={internalPadding}
            >
                {subheading}
            </Text>
            {children}
            {redirectUrl?.length ? (
                <AuthWrapper
                    component={<ButtonComponent />}
                    redirectUrl={redirectUrl}
                    loginTrigger="home-page"
                />
            ) : (
                <ButtonComponent onClickEvent={onClickEvent} />
            )}
        </View>
    );
};

export default Convincer;
