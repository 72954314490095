import React from 'react';
import { View, colors, Button } from '@fairygodboss/ui';
import Convincer from './components/Convincer';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';
import { AdviceSvg } from './assets/svgs/Advice';
import { EmitMetric } from '../../../components/Analytics/VisibilityPixel/VisibilityPixel';

const MobileAdviceButton = ({ onButtonPress }) => {
    return (
        <Button
            onPress={onButtonPress}
            width={{ _: '100%', sm: 'auto' }}
            size="large"
            mt="32px"
            mb="18px"
        >
            Get advice
        </Button>
    );
};

export const AdviceBlock = ({
    firstHeading = 'Get advice from peers and experts',
    firstSubheading = 'Salaries, work struggles, negotiations, and more. Our community helps you navigate your career. ',
    secondHeading = 'Explore popular career topics',
    secondSubheading = 'Get your biggest career questions answered by a community of women empowering women.',
}) => {
    const onAdviceButtonClick = () => {
        EmitMetric({
            misc_event_type: 'advice-button-click',
            click: 1,
        });

        window.open('/advice', '_self');
    };

    return (
        <View
            className="homepage-section"
            data-qa="advice-block"
            height={{
                _: 'inherit',
                lg: '1176px',
            }}
            position="relative"
        >
            <View
                zIndex="-1"
                position="absolute"
                bg={colors.info.light}
                width="100vw"
                height="100%"
                css={{
                    transform: 'translateX(-50%)',
                    marginLeft: '50%',
                }}
            />
            <View
                height="100%"
                px={{ _: '16px', sm: '89px', md: '24px', lg: '44px' }}
            >
                <View
                    pt={{ _: '48px', sm: '70px', md: '118px', lg: '106px' }}
                    display="flex"
                    alignItems={{
                        _: 'center',
                        sm: 'baseline',
                        md: 'start',
                        lg: 'center',
                    }}
                    justifyContent="space-between"
                    flexDirection={{ _: 'column-reverse', md: 'row' }}
                >
                    <View
                        flex={{ _: 'inherit', md: 6, lg: 'inherit' }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                    >
                        <View
                            width={{
                                _: '100%',
                                sm: '422px',
                                md: '100%',
                                lg: '564px',
                            }}
                            minWidth={{
                                _: null,
                                sm: null,
                                md: 417,
                                lg: null,
                            }}
                            height={{
                                _: '100%',
                                sm: '422px',
                                md: '100%',
                                lg: '556px',
                            }}
                            pt={{ _: '32px', md: '32px', lg: '0px' }}
                            overflow="initial"
                        >
                            <img
                                src={buildImagePath(
                                    './Homepage/images/PostAndComments.png',
                                    'img',
                                    {
                                        img: 'c_scale,f_auto,q_auto',
                                    }
                                )}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    imageRendering: '-webkit-optimize-contrast',
                                    aspectRatio: '854 / 785',
                                }}
                                alt="Anonymous poster asking for information about how to negotiate a job offer with other Fairygodboss memebers providing insight"
                            />
                        </View>
                    </View>
                    <View
                        display={{ _: 'none', md: 'flex', lg: 'none' }}
                        flex={{ _: 'inherit', md: 1, lg: 'inherit' }}
                    />
                    <Convincer
                        width={{
                            _: null,
                            sm: '424px',
                            md: '100%',
                            lg: '466px',
                        }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                        flex={{ _: 'inherit', md: 5, lg: 'inherit' }}
                        mb={{ _: '0px', md: '74px', lg: '74px' }}
                        ml={{ _: '0px', md: '0px', lg: '0px' }}
                        mr={{ _: '0px', md: '54px', lg: '0px' }}
                        heading={firstHeading}
                        subheading={firstSubheading}
                        buttonText="Join us"
                        buttonWidth="105px"
                        buttonDisplay={{ _: 'none', md: 'flex' }}
                        redirectUrl="/community/feed"
                    />
                </View>
                <View
                    pt={{ _: '62px', sm: '12px', md: '64px', lg: '24px' }}
                    mt={{ _: '-12px', sm: '-30px', md: '-12px', lg: '0px' }}
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection={{ _: 'column', md: 'row' }}
                >
                    <Convincer
                        width={{
                            _: '100%',
                            sm: '424px',
                            md: '100%',
                            lg: '564px',
                        }}
                        mr={{ _: '0px', md: '23px', lg: '0px' }}
                        flex={{ _: 'inherit', md: 6, lg: 'inherit' }}
                        alignSelf={{
                            _: 'inherit',
                            sm: 'center',
                            md: 'inherit',
                        }}
                        pt={{ _: '0px', lg: '24px' }}
                        pb={{ _: '0px', md: '112px', lg: '0px' }}
                        mt={{ _: '0px', sm: '24px', md: '0px' }}
                        heading={secondHeading}
                        subheading={secondSubheading}
                        buttonText="Get advice"
                        onClickEvent={onAdviceButtonClick}
                        buttonMarginTop={{ _: '50px', md: '32px' }}
                        buttonDisplay={{ _: 'none', sm: 'flex' }}
                    />
                    <View
                        display={{ _: 'flex', sm: 'inherit' }}
                        justifyContent={{ _: 'center', md: 'inherit' }}
                        flex={{ sm: 'inherit', md: 6, lg: 'inherit' }}
                        flexDirection={{ _: 'column', sm: 'inherit' }}
                    >
                        <View
                            alignSelf={{ _: 'center', md: 'auto' }}
                            height={{ _: '100%', sm: '387px', md: '380px' }}
                            width={{
                                _: '104vw',
                                sm: '524px',
                                md: '100%',
                                lg: '564px',
                            }}
                            pt={{
                                _: '20px',
                                sm: '0px',
                                md: '0px',
                                lg: '0px',
                            }}
                            pl={{
                                _: '0px',
                                sm: '0px',
                                md: '0px',
                                lg: '0px',
                            }}
                            mt={{
                                _: '0px',
                                sm: '48px',
                                md: '-20px',
                                lg: '0px',
                            }}
                            ml={{
                                _: '0px',
                                sm: '-12%',
                                md: '0px',
                            }}
                            mr={{
                                _: '0px',
                                sm: '-12%',
                                md: '0px',
                            }}
                            overflow="initial"
                            onClick={onAdviceButtonClick}
                            css={{ cursor: 'pointer' }}
                        >
                            <AdviceSvg />
                        </View>
                        <View display={{ _: 'inherit', sm: 'none' }}>
                            <MobileAdviceButton
                                onButtonPress={onAdviceButtonClick}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};
