import React from 'react';

export const TiktokIcon20 = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <title>Check out Fairygodboss on TikTok</title>
            <path
                d="M16.8743 5.84278C15.892 5.81313 15.0021 5.34262 14.331 4.71371C13.9642 4.36438 13.6735 3.94294 13.4774 3.47595C13.2814 3.00896 13.184 2.50645 13.1914 2H10.4565V12.5314C10.4565 14.2518 9.42524 15.2657 8.15808 15.2657C7.82212 15.2694 7.48946 15.1994 7.18336 15.0606C6.87738 14.9219 6.60542 14.7179 6.38681 14.4627C6.16821 14.2076 6.00817 13.9076 5.91791 13.5841C5.82776 13.2605 5.80951 12.921 5.86467 12.5897C5.91983 12.2582 6.0469 11.9428 6.23702 11.6659C6.42714 11.3889 6.67573 11.1569 6.96518 10.9864C7.25463 10.8157 7.5779 10.7106 7.91237 10.6783C8.24685 10.6461 8.58441 10.6876 8.90107 10.7998V8.01878C8.65707 7.96513 8.40795 7.93878 8.15808 7.94017C7.16309 7.93996 6.19029 8.23479 5.3627 8.78732C4.53521 9.33985 3.89027 10.1253 3.50928 11.0445C3.12829 11.9634 3.02853 12.975 3.2225 13.9507C3.41657 14.9264 3.8956 15.8229 4.59912 16.5263C5.30263 17.2299 6.19915 17.709 7.17504 17.9033C8.15104 18.0974 9.16267 17.9979 10.082 17.6173C11.0014 17.2365 11.7871 16.5919 12.34 15.7647C12.8929 14.9375 13.188 13.9649 13.188 12.9702V7.3022C14.2477 8.03478 15.5137 8.38113 16.8652 8.40503L16.8743 5.84278Z"
                fill="white"
            />
        </svg>
    );
};
