import React from 'react';

export const Breifcase = () => {
    return (
        <svg
            width="68"
            height="48"
            viewBox="0 0 68 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="8" y="9" width="52" height="39" rx="5" fill="#2A4473" />
            <g filter="url(#filter0_d_860_138011)">
                <rect
                    x="8"
                    y="9"
                    width="52"
                    height="17"
                    rx="5"
                    fill="#50668C"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 0C26.6863 0 24 2.68629 24 6V9H27V7C27 4.79086 28.7909 3 31 3H37C39.2091 3 41 4.79086 41 7V9H44V6C44 2.68629 41.3137 0 38 0H30Z"
                fill="#9DA9BF"
            />
            <rect
                x="32"
                y="23.5349"
                width="4"
                height="6.13953"
                rx="1"
                fill="#C4CBD8"
            />
            <defs>
                <filter
                    id="filter0_d_860_138011"
                    x="0"
                    y="5"
                    width="68"
                    height="33"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0 0.207843 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_860_138011"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_860_138011"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
