import React, { useEffect, useCallback, useState } from 'react';
import { View, Heading, Text, colors, Button, Icon } from '@fairygodboss/ui';
import { JobPosting } from './components/JobPosting';
import { JobsInputField } from './components/JobsInputField';
import { buildImagePath } from '../../../components/JobComponents/Company/Image/logo';

const jobSearchList = [
    'Software Engineer',
    'Account Executive',
    'Visual Designer',
    'Data Analyst',
    'Product Manager',
    'Marketing',
    'Project Manager',
    'Program Manager',
    'Customer Service',
    'Art Director',
    'Administrative Assistant',
    'Nurse',
    'Technical Support',
    'Financial Advisor',
    'Accountant',
    'Human Resources',
];

const LogosTopRow = () => {
    return (
        <>
            <Logo
                url="/company-overview/power-home-remodeling-group"
                src={buildImagePath(
                    './Homepage/images/PowerHomeRemodelingLogo.png',
                    'img',
                    {
                        img: 'c_scale,f_auto,q_auto',
                    }
                )}
                alt="Power Home Remodeling"
                width={{ _: '39px', sm: '46px' }}
                height={{ _: '48px', sm: '56px' }}
            />

            <Logo
                url="/company-overview/elastic"
                src={buildImagePath(
                    './Homepage/images/ElasticLogo.png',
                    'img',
                    {
                        img: 'c_scale,f_auto,q_auto',
                    }
                )}
                alt="Elastic"
                width={{ _: '70px', sm: '82px' }}
                height={{ _: '24px', sm: '28.11px' }}
            />

            <Logo
                url="/company-overview/dtcc"
                src={buildImagePath('./Homepage/images/DTCCLogo.png', 'img', {
                    img: 'c_scale,f_auto,q_auto',
                })}
                alt="DTCC"
                width={{ _: '54.79px', sm: '69px' }}
                height={{ _: '13px', sm: '18px' }}
            />

            <Logo
                url="/company-overview/pariveda-solutions"
                src={buildImagePath(
                    './Homepage/images/ParivedaLogo.png',
                    'img',
                    {
                        img: 'c_scale,f_auto,q_auto',
                    }
                )}
                alt="Pariveda"
                width={{ _: '91.93px', sm: '113px' }}
                height={{ _: '13px', sm: '16px' }}
            />
        </>
    );
};

const LogosBottomRow = () => {
    return (
        <>
            <Logo
                url="/company-overview/ukg"
                src={buildImagePath('./Homepage/images/UKGLogo.png', 'img', {
                    img: 'c_scale,f_auto,q_auto',
                })}
                alt="UKG"
                width={{ _: '41.76px', sm: '50px' }}
                height={{ _: '13.35px', sm: '16px' }}
            />
            <Logo
                url="/company-overview/hanover"
                src={buildImagePath(
                    './Homepage/images/TheHanoverInsuranceGroupLogo.png',
                    'img',
                    {
                        img: 'c_scale,f_auto,q_auto',
                    }
                )}
                alt="The Hanover Insurance Group"
                width={{ _: '69.53px', sm: '83px' }}
                height={{ _: '21.7px', sm: '26px' }}
            />
            <Logo
                url="/company-overview/squarespace"
                src={buildImagePath(
                    './Homepage/images/SquarespaceLogo.png',
                    'img',
                    {
                        img: 'c_scale,f_auto,q_auto',
                    }
                )}
                alt="Squarespace"
                width={{ _: '118.72px', sm: '142px' }}
                height={{ _: '16.69px', sm: '20px' }}
            />
        </>
    );
};

const Logo = ({ src, alt, width, height, url }) => {
    return (
        <View
            width={width}
            height={height}
            display="flex"
            mx={{ _: '0px', sm: '16px', md: '0px' }}
            mt={{ _: '16px', md: '0px' }}
            css={{
                alignItems: 'flex-start',
            }}
        >
            <View as="a" href={url} target="_self" css={{ cursor: 'pointer' }}>
                <View
                    as="img"
                    src={src}
                    width={width}
                    height={height}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                    alt={alt}
                />
            </View>
        </View>
    );
};

const Logos = ({ sponsoredCompanies }) => {
    const injectedTransforms = {
        xSmall: 'c_lpad,f_auto,q_auto',
    };
    // eslint-disable-next-line no-unused-vars
    const imageMap = sponsoredCompanies.map((c) => {
        const imagePath = buildImagePath(
            c.logo.src,
            c.size || 'xSmall',
            injectedTransforms
        );

        return imagePath;
    });
    return (
        <View
            minWidth="330px"
            py="20px"
            justifyContent={{
                _: 'center',
                sm: 'space-around',
                md: 'space-between',
            }}
            px={{ _: '0px', sm: '0px', md: '0px', lg: '24px' }}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            mt={{
                _: '-26px',
                md: '0px',
            }}
            width={{
                _: '100%',
                sm: 'inherit',
            }}
            overflow="hidden"
            alignSelf={{ _: 'center', md: null }}
            height="auto"
        >
            <View display={{ _: 'none', md: 'contents' }}>
                <LogosTopRow />
                <LogosBottomRow />
            </View>
            <View
                display={{ _: 'inherit', md: 'none' }}
                width="100%"
                flexDirection="column"
            >
                <View
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    mx={{ _: '0px', sm: '-32px' }}
                >
                    <LogosTopRow />
                </View>
                <View
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    mx={{ _: '0px', sm: '-32px' }}
                >
                    <LogosBottomRow />
                </View>
            </View>
        </View>
    );
};

const JobSearchButton = ({ term }) => {
    const onJobClick = () => {
        window.open(`/jobs/find?keywords=${term}`, '_self');
    };

    return (
        <Button
            onPress={onJobClick}
            mr={{ _: '12px', sm: '16px' }}
            mt="16px"
            variant="secondaryText"
            size="large"
            px="20px"
            py="11px"
            css={{
                backgroundColor: colors.secondary[10],
                span: {
                    color: colors.neutral[100],
                    letterSpacing: '-0.4px',
                    fontSize: '14px',
                    lineHeight: '18px',
                },
            }}
        >
            {term}
        </Button>
    );
};

const Partner = () => {
    const onPartnerClick = () => {
        window.open(`/employers`, '_self');
    };

    return (
        <View
            display={{ _: 'none', sm: 'flex' }}
            height="72px"
            width="100vw"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            fontWeight="600"
            lineHeight="24px"
            left="0"
            bg={`${colors.secondary[10]}80`}
            color={colors.neutral[100]}
            maxWidth="100%"
            overflowX="hidden"
            css={{
                button: {
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                },
            }}
        >
            Looking to hire more women at your company?
            <Button
                onPress={onPartnerClick}
                size="large"
                variant="primaryText"
                css={{
                    span: { display: 'flex' },
                    ':hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                    },
                    svg: {
                        minWidth: '15px',
                        minHeight: '15px',
                    },
                }}
                ml="8px"
                py="6px"
                px="16px"
                maxWidth="189px"
                fontSize="16px"
                lineHeight="24px"
            >
                Become a partner
                <Icon
                    pt="4px"
                    pl="6px"
                    variant="chevron_right_16"
                    outlineColor={colors.primary[60]}
                />
            </Button>
        </View>
    );
};

export const JobsBlock = ({ sponsoredCompanies }) => {
    const [width, setWidth] = useState();

    const windowResize = useCallback(() => {
        const _width = window?.innerWidth;
        setWidth(_width);
    }, []);

    useEffect(() => {
        windowResize();
        window.addEventListener('resize', windowResize, false);
        return () => window.removeEventListener('resize', windowResize);
    }, [windowResize]);

    const isSm = width <= 903;
    const jobListLength = 16;

    return (
        <View
            mb={{ _: '0px', sm: '72px' }}
            className="homepage-section"
            data-qa="jobs-block"
        >
            <View
                display="flex"
                px="24px"
                pt={{ _: '48px', sm: '80px' }}
                justifyContent={{ sm: 'center' }}
            >
                <View
                    flex="7"
                    maxWidth={{
                        _: '100%',
                        sm: '568px',
                        md: '530px',
                        lg: '100%',
                    }}
                    justifyContent={{ _: 'center', sm: 'start', md: 'center' }}
                    display={{ _: 'flex' }}
                    px={{ _: '0px', sm: '0px', md: '0px' }}
                >
                    <View width={{ _: '501px', md: '565px', lg: '662px' }}>
                        <Heading
                            variant="headingLg"
                            as="h3"
                            fontSize={{ sm: '42px', md: '52px' }}
                            lineHeight={{ sm: '56px', md: '70px' }}
                            color={colors.neutral[100]}
                            maxWidth={{ _: '360px', sm: '100%' }}
                        >
                            Find your next job opportunity
                        </Heading>
                        <Text
                            pt="24px"
                            fontSize={{ _: '16px', md: '18px' }}
                            lineHeight={{ _: '26px', md: '30px' }}
                            color={colors.neutral[100]}
                            mb="12px"
                        >
                            Suggested searches
                        </Text>
                        <View
                            maxWidth={{ _: '100%', sm: '100%' }}
                            minWidth={{ _: '338px', sm: '100%' }}
                            maxHeight="280px"
                            overflow="hidden"
                        >
                            {jobSearchList.slice(0, jobListLength).map((t) => {
                                return (
                                    <View display="contents" key={t}>
                                        <JobSearchButton
                                            term={t}
                                            _key={`jobs-search-${t}`}
                                        />
                                    </View>
                                );
                            })}
                        </View>
                        <JobsInputField />
                    </View>
                </View>
                <View
                    flex="5"
                    display={{ _: 'none', md: 'inherit' }}
                    mt={{ md: '90px', lg: '56px' }}
                    justifyContent="end"
                >
                    <JobPosting />
                </View>
            </View>
            <View
                display="flex"
                px="24px"
                mt={{ _: '25px', lg: '72px' }}
                maxWidth="1240px"
                justifyContent="center"
            >
                <View
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    flexDirection="column"
                    maxWidth={{ _: 'inherit', sm: '568px', md: 'inherit' }}
                >
                    <View
                        textAlign={{ _: 'center', md: null }}
                        display={{ _: 'flex', md: null }}
                        justifyContent={{ _: 'center', md: null }}
                    >
                        <View
                            pt={{ _: '52px', lg: '20px' }}
                            pb="5px"
                            justifyContent="center"
                            display={{ _: 'grid', md: 'flex' }}
                            width={{ _: '400px', sm: '100%', md: '100%' }}
                            color={colors.neutral[100]}
                            px={{ _: '0px', sm: '0px', md: '0px' }}
                            minWidth={{ _: '100%', sm: '365px', md: '100%' }}
                            fontSize={{ _: '16px', sm: '18px', md: '20px' }}
                            fontWeight="600"
                            lineHeight={{ _: '24px', sm: '30px' }}
                        >
                            Work at The Best Companies for Women, anonymously
                            reviewed
                            <View>
                                <Button
                                    onPress={() => {
                                        window.open(
                                            '/best-companies-for-women-2023',
                                            '_self'
                                        );
                                    }}
                                    ml="4px"
                                    size="large"
                                    variant="primaryText"
                                    mt={{ _: '6px', md: '-12px' }}
                                    css={{
                                        span: { display: 'flex' },
                                        ':hover': {
                                            backgroundColor: 'transparent',
                                            textDecoration: 'underline',
                                        },
                                        svg: {
                                            minWidth: isSm ? '15px' : '21px',
                                            minHeight: isSm ? '15px' : '21px',
                                        },
                                    }}
                                    py="10px"
                                    px="20px"
                                    maxWidth="133px"
                                    fontSize={{
                                        _: '16px',
                                        sm: '18px',
                                        md: '20px',
                                    }}
                                    lineHeight={{ _: '19px', sm: '30px' }}
                                >
                                    See all
                                    <Icon
                                        pt={{ _: '1px', sm: '7px', md: '3px' }}
                                        pl="7px"
                                        variant="chevron_right_16"
                                        outlineColor={colors.primary[60]}
                                    />
                                </Button>
                            </View>
                        </View>
                    </View>
                    <Logos sponsoredCompanies={sponsoredCompanies} />
                </View>
            </View>
            <Partner />
        </View>
    );
};
