import React from 'react';
import { View, Text, colors } from '@fairygodboss/ui';

export const FooterLinksListSection = ({
    sectionTitle = {
        value: '',
        link: '',
    },
    sectionItems = [],
    otherProps,
}) => {
    return (
        <View marginBottom="40px" flexGrow="1" {...otherProps}>
            <a
                href={sectionTitle.link}
                css={{
                    textDecoration: 'none !important',
                    WebkitUserSelect: 'none !important' /* Safari */,
                    MozUserSelect: 'none !important' /* Firefox */,
                    msUserSelect: 'none !important' /* IE10+/Edge */,
                    userSelect: 'none !important' /* Standard */,
                    WebkitTapHighlightColor: 'transparent',
                }}
            >
                <Text
                    fontSize="16px"
                    color={colors.white}
                    lineHeight="24px"
                    fontWeight={700}
                    css={{
                        '&:hover': {
                            color: colors.primary[30],
                        },
                        WebkitUserSelect: 'none !important' /* Safari */,
                        MozUserSelect: 'none !important' /* Firefox */,
                        msUserSelect: 'none !important' /* IE10+/Edge */,
                        userSelect: 'none !important' /* Standard */,
                        WebkitTapHighlightColor: 'transparent',
                    }}
                    mb="30px"
                >
                    {sectionTitle.value}
                </Text>
            </a>
            {sectionItems.map((sectionItem) => (
                <a
                    key={sectionItem.link}
                    href={sectionItem.link}
                    target={sectionItem.openInNewTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                    css={{
                        textDecoration: 'none !important',
                        WebkitUserSelect: 'none !important' /* Safari */,
                        MozUserSelect: 'none !important' /* Firefox */,
                        msUserSelect: 'none !important' /* IE10+/Edge */,
                        userSelect: 'none !important' /* Standard */,
                        WebkitTapHighlightColor: 'transparent',
                        'not(.touch):hover': {
                            color: '#FFF',
                        },
                    }}
                >
                    <Text
                        fontSize="14px"
                        color={colors.white}
                        lineHeight="24px"
                        fontWeight="600"
                        css={{
                            '&:hover': {
                                color: colors.primary[30],
                            },
                            cursor: 'pointer',
                            WebkitUserSelect: 'none !important' /* Safari */,
                            MozUserSelect: 'none !important' /* Firefox */,
                            msUserSelect: 'none !important' /* IE10+/Edge */,
                            userSelect: 'none !important' /* Standard */,
                            WebkitTapHighlightColor: 'transparent',
                            mx: '10px',
                        }}
                    >
                        {sectionItem.value}
                    </Text>
                </a>
            ))}
        </View>
    );
};
