import React, { useState, useEffect, useCallback } from 'react';
import { View, Button, InputField, colors } from '@fairygodboss/ui';

export const JobsInputField = () => {
    const [value, setValue] = useState({ value: '' });
    const [isInline, setIsInline] = useState();
    const changeHandler = (e) => setValue({ value: e.target.value });

    const windowResize = useCallback(() => {
        const _isInline = window?.innerWidth >= 499;
        setIsInline(_isInline);
    }, []);

    useEffect(() => {
        windowResize();
        window.addEventListener('resize', windowResize, false);
        return () => window.removeEventListener('resize', windowResize);
    }, [windowResize]);

    const onFindJobsClick = () => {
        window.open(`/jobs/find?keywords=${value?.value}`, '_self');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e?.target?.blur();
            if (e.target?.placeholder !== 'Anywhere') {
                e?.target?.focus();
            }
            onFindJobsClick();
        }
    };

    return (
        <View
            display={{
                _: isInline ? 'flex' : 'block',
                sm: 'flex',
            }}
            pt="32px"
        >
            <View
                border={`1px solid ${colors.neutral[60]}`}
                borderRadius="4px"
                width={{ _: '100%' }}
                maxWidth={{ _: isInline ? '368px' : '100%', sm: '368px' }}
                css={{
                    input: {
                        width: '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '14px',
                        paddingBottom: '14px',
                        lineHeight: '20px',
                        '::placeholder': {
                            color: colors.neutral[70],
                        },
                    },
                }}
            >
                <InputField
                    placeholder="Search Job Title, Company, or Keyword"
                    value={value.value}
                    changeHandler={changeHandler}
                    onKeyDown={handleKeyDown}
                />
            </View>
            <View
                css={{
                    button: {
                        backgroundColor: colors.secondary[60],
                        borderColor: 'transparent',
                        ':hover': {
                            backgroundColor: colors.secondary[70],
                        },
                    },
                    span: { overflow: 'visible' },
                }}
            >
                <Button
                    onPress={onFindJobsClick}
                    mt={{ _: isInline ? '0px' : '16px', sm: '0px' }}
                    ml={{ _: isInline ? '16px' : '0px', sm: '16px' }}
                    width={{
                        _: !isInline ? '100%' : '123px',
                        sm: '123px',
                    }}
                    height="49px"
                    size="large"
                >
                    Find jobs
                </Button>
            </View>
        </View>
    );
};
